import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { motion, useTransform, useMotionValue } from 'framer-motion';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import { ScrollContext } from '../context';

import * as c from '../components';

export default ({ children, cursor, first = false }) => {
	const hoverStart = () => {
		window.changeCursor(cursor);
	};
	const hoverEnd = () => {
		window.restoreCursor();
	};

	return (
		<View first={first} className='view' onHoverStart={hoverStart} onHoverEnd={hoverEnd}>
			<Controller>
				<Scene duration='80%' triggerHook={0.9} className='scene'>
					{(progress) => (
						<article>
							<ScrollContext.Provider value={progress}>{children}</ScrollContext.Provider>
						</article>
					)}
				</Scene>
			</Controller>
		</View>
	);
};

const View = styled(motion.section)`
	display: flex;
	flex-direction: column;
	position: relative;
	box-sizing: border-box;
	width: 100vw;
	min-height: 55vw;
	overflow: hidden;
	padding: 10vw 0 0vw 0;
	font-size: 100px;
	color: white;
	background-color: ${(p) => p.theme.colors.grayDark}
		${(props) =>
			props.first &&
			css`
				padding: 0vw 0 5vw 0;
			`};
	// border-top: 5px solid blue;
`;
