/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { fetcher, QUERY_SINGLE_PAGE } from '../services';
import DOMPurify from 'dompurify';
import useSWR from 'swr';

import * as c from '../components';

const { sanitize } = DOMPurify;

export default () => {
	const params = useParams();
	const { pathname } = useLocation();
	const [local, setLocal] = useState(null);
	const [variables, setVariables] = useState(null);
	const { data, error } = useSWR(variables ? [QUERY_SINGLE_PAGE, variables] : null, fetcher);

	const prepare = (text) => {
		return sanitize(text).replace(/(\r\n)/g, `<br/>`);
	};

	useEffect(() => {
		if (params) {
			setVariables({ uri: pathname });
		}
	}, [pathname]);

	useEffect(() => {
		if (data) {
			setLocal({ ...data.page.tmpl_internas.blocks[0] });
		}
	}, [data]);
	return (
		<>
			<motion.article {...A} className='relative w-screen h-auto h-min-screen pb-20'>
				{local && (
					<>
						<div className='md:ml-72 md:w-full'>
							<h1
								className='text-white font-albra-bd text-3xl px-7 mt-16 md:px-0 md:w-1/2 md:mt-32 md:text-7xl 2xl:text-8xl'
								dangerouslySetInnerHTML={{ __html: prepare(local.title) }}
							/>
							<h2
								className='text-white font-mon text-xl mt-4 px-7 md:px-0 md:w-1/2 2xl:text-2xl'
								dangerouslySetInnerHTML={{ __html: prepare(local.subtitle) }}
							/>
							<p
								className='text-white font-mon text-sm mt-4 px-7 md:px-0 md:w-1/2 2xl:text-base'
								dangerouslySetInnerHTML={{ __html: prepare(local.text) }}
							/>
							<p
								className='text-white font-mon text-sm mt-10 px-7 italic md:px-0 md:w-1/2'
								dangerouslySetInnerHTML={{ __html: prepare(local.obs) }}
							/>
						</div>
					</>
				)}
			</motion.article>
			<AnimatePresence exitBeforeEnter>{!data && <c.Loader />}</AnimatePresence>
		</>
	);
};

const A = {
	variants: {
		initial: { opacity: 0 },
		exit: { opacity: 0 },
		enter: { opacity: 1 },
	},
	animate: `enter`,
	initial: `initial`,
	exit: `exit`,
};

const View = styled(motion.div)`
	position: absolute;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: var(--dark-grey);
`;
