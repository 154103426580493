import React, { useEffect, useState } from 'react';
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { getAreaColorByUrl } from '../state';

export default () => {
	const [color, setColor] = useState(`#FFF`);
	const { pathname } = useLocation();
	const { scrollYProgress } = useViewportScroll();

	const width = useTransform(scrollYProgress, [0, 1], [`0vw`, `100vw`]);

	useEffect(() => {
		if (pathname) {
			setColor(getAreaColorByUrl(pathname));
		}
	}, [pathname]);
	return (
		<motion.div
			className='fixed left-0 top-0 h-1 md:h-2 w-0'
			transition={{ ease: 'linear', duration: 0.2 }}
			style={{ backgroundColor: color, width, zIndex: 1000 }}
		/>
	);
};
