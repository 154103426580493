import React, { useState, useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { motion, useTransform, useMotionValue } from 'framer-motion';
import { ScrollContext } from '../context';

// during zoom scale
export default ({ image = null, onTap, first = false, behaviour = 'cover' }) => {
	const [loading, setLoading] = useState(true);
	const value = useContext(ScrollContext);
	const motionValue = useMotionValue(0);
	const scale = useTransform(motionValue, [0, 1], [1, 1.4]);

	useEffect(() => {
		motionValue.set(value);
	}, [value]);

	const loaded = () => {
		setLoading(false);
	};

	const handle = () => {
		if (onTap) {
			onTap(image);
		}
	};

	return (
		<View>
			<motion.picture
				className={behaviour}
				onHoverStart={() => window.changeCursor(`mais`)}
				onHoverEnd={() => window.restoreCursor()}
				onTap={handle}
				animate={loading ? `hidden` : `visible`}
				initial={`hidden`}
				style={{ pointerEvents: `auto` }}
				variants={{
					visible: { backgroundColor: `#2d2d2d` },
					hidden: { backgroundColor: image?.backgroundColor },
				}}>
				<Image src={image?.img?.mediaItemUrl} scale={scale} onLoad={loaded} alt={image?.ariaDescription} />
			</motion.picture>
		</View>
	);
};

const Image = ({ src, scale, alt, onLoad }) => {
	const [loading, setLoading] = useState(true);
	const present = () => {
		setLoading(false);
		if (onLoad) onLoad();
	};

	return (
		<ImageView
			src={src}
			alt={alt}
			onLoad={present}
			initial={`hidden`}
			animate={!loading ? `visible` : `hidden`}
			style={{ scale }}
			variants={{ visible: { opacity: 0.7 }, hidden: { opacity: 0 } }}
		/>
	);
};

const ImageView = styled(motion.img)`
	width: 100%;
	height: auto;
`;

const View = styled(motion.figure)`
	position: absolute;
	diplay: block;
	left: 0;
	top: 0;
	width: 100vw;
	height: 60.556vw;

	& > picture {
		position: absolute;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	& > picture.partial {
		left: 60vw;
		top: 7.813vw;
		width: 33.854vw;
		height: 45.417vw;
	}

	& > picture.full {
		left: 12.708vw;
		top: 5.208vw;
		width: 74.583vw;
		height: 36.458vw;
	}

	& > picture.cover {
		top: 0;
		width: 100vw;
		height: 41.666vw;
	}
`;
