/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { useGlobalState } from '../state';

import styled from 'styled-components';

export default () => {
	const [navigation] = useGlobalState(`navigation_flat`);
	const { pathname } = useLocation();
	const [path, setPath] = useState(null);

	useEffect(() => {
		if (pathname && navigation) {
			const path = [];
			let parent = null;
			let page = navigation.find(node => node.url === pathname);
			if (page) {
				path.unshift({ label: page.label, url: page.url });
				if (page.parentId) {
					parent = navigation.find(node => node.id === page.parentId);
					path.unshift({ label: parent.label, url: parent.url });
				}
				setPath(path);
			}
		}
	}, [pathname, navigation]);

	return (
		<aside className='fixed left-0 top-0 w-full'>
			<nav>
				<div className='w-full h-40 bg-gradient-to-b from-black to-transparent opacity-40' />
				<motion.ul className='absolute left-72 top-7 font-mon text-white text-base flex flex-row'>
					{pathname.indexOf(`/en`) !== -1 && (
						<li className='uppercase'>
							the first Peoples of <span className='font-albra-bd'>brazil</span>
						</li>
					)}
					{pathname.indexOf(`/es`) !== -1 && (
						<li className='uppercase'>
							Los Primeros <span className='font-albra-bd'>Brasileños</span>
						</li>
					)}
					{(pathname.indexOf(`/pt`) !== -1 || pathname === '/') && (
						<li className='uppercase'>
							os primeiros <span className='font-albra-bd'>brasileiros</span>
						</li>
					)}
					{path?.map((fragment, i) => (
						<motion.li key={fragment.url}>
							<motion.a
								initial={{ opacity: 0 }}
								variants={{
									enter: custom => ({ opacity: 1, transition: { delay: custom * 0.1 } }),
									exit: { opacity: 1 },
								}}
								animate={`enter`}
								custom={i}
								href={fragment.url}
								onHoverStart={() => window.changeCursor(`select`)}
								onHoverEnd={() => window.restoreCursor()}>
								&nbsp;/&nbsp;{fragment.label}
							</motion.a>
						</motion.li>
					))}
					<LangView />
				</motion.ul>
			</nav>
		</aside>
	);
};

const LangView = () => {
	const { pathname } = useLocation();

	return (
		// <Lang
		// 	onHoverStart={() => window.changeCursor(`select`)}
		// 	onHoverEnd={() => window.restoreCursor()}
		// 	style={{ pointerEvents: `auto` }}
		// 	onTap={e => {
		// 		setTimeout(() => {
		// 			window.location.href = pathname.indexOf(`/en`) !== -1 ? `/pt` : `/en`;
		// 		}, 500);
		// 	}}>
		<div className='absolute flex flex-none w-auto top-0 text-xs uppercase gap-x-4' style={{ left: '50vw' }}>
			{pathname?.indexOf('/en') === -1 && (
				<motion.div
					className='lng flex-none'
					onHoverStart={() => window.changeCursor(`select`)}
					onHoverEnd={() => window.restoreCursor()}
					style={{ pointerEvents: `auto` }}
					onTap={e => {
						setTimeout(() => {
							window.location.href = `/en`;
						}, 500);
					}}>
					english version
				</motion.div>
			)}
			{pathname?.indexOf('/es') === -1 && (
				<motion.div
					className='lng flex-none'
					style={{ pointerEvents: `auto` }}
					onHoverStart={() => window.changeCursor(`select`)}
					onHoverEnd={() => window.restoreCursor()}
					onTap={e => {
						setTimeout(() => {
							window.location.href = `/es`;
						}, 500);
					}}>
					versión en español
				</motion.div>
			)}
			{pathname?.indexOf('/pt') === -1 && pathname !== '/' && (
				<motion.div
					className='lng flex-none'
					style={{ pointerEvents: `auto` }}
					onHoverStart={() => window.changeCursor(`select`)}
					onHoverEnd={() => window.restoreCursor()}
					onTap={e => {
						setTimeout(() => {
							window.location.href = `/pt`;
						}, 500);
					}}>
					versão em português
				</motion.div>
			)}
		</div>
		// </Lang>
	);
};

const View = styled(motion.nav).attrs(props => ({
	className: `type-white`,
}))`
	pointer-events: none;
	position: fixed;
	left: 0;
	top: 0;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 0 0vw 3vw 18.403vw;
	width: 100vw;
	height: 10vw;
	background-image: ${props => props.theme.colors.grayDarkGradient};
	z-index: 100;

	ul {
		display: flex;
		flex-direction: row;
		z-index: 1000;
	}

	& > ul > li > a {
		pointer-events: auto;
		text-decoration: none;
		color: white;

		&:hover,
		&:link,
		&:visted {
			color: white;
		}
	}
`;

const Lang = styled(motion.div)`
	color: white;
	font-family: MontserratRegular;
	font-size: 0.8vw;
	text-transform: uppercase;
	pointer-events: auto;

	z-index: 10;

	user-select: none;
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-o-user-select: none;

	curso: pointer;

	margin-left: 52vw;

	filter: drop-shadow(0 0 0.75rem black);
`;
