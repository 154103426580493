/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation, useParams } from 'react-router-dom';
import FileSaver from 'file-saver';
import { fetcher, QUERY_CONTEUDOS_EXTRAS } from '../services';
import ReactPlayer from 'react-player';
import useSWR from 'swr';
import slugify from 'slugify';
import path from 'path-browserify';

export default () => {
	const { locale } = useParams();
	const { pathname } = useLocation();
	const [local, setLocal] = useState(null);
	const [variables, setVariables] = useState(null);
	const { data, error } = useSWR(variables ? [QUERY_CONTEUDOS_EXTRAS, variables] : null, fetcher);

	useEffect(() => {
		if (pathname) {
			setVariables({ uri: pathname });
		}
	}, [pathname]);

	useEffect(() => {
		if (data) {
			const page = { list: [...data.page.conteudos_extras.list], title: data.page.title };
			setLocal({ ...page });
		}
	}, [data]);

	return (
		<section className='w-4/6 mt-40 ml-72'>
			<h1 className='w-1/2 font-albra-bd text-white text-7xl '>{local?.title}</h1>
			<article className='grid grid-cols-3 w-full min-h-screen mt-20'>
				{local?.list?.map((item) => (
					<>{!item.isExpo && <Book key={item.id} locale={locale} item={item} />}</>
				))}
			</article>

			<div className='w-full aspect-w-16 aspect-h-9'>
				<ReactPlayer url='https://www.youtube.com/watch?v=J8AS-aeqsfU' width='100%' height='100%' />
			</div>

			<article className='grid grid-cols-3 w-full min-h-screen mt-20'>
				{local?.list?.map((item) => (
					<>{item.isExpo && <Book locale={locale} key={item.id} item={item} />}</>
				))}
			</article>
		</section>
	);
};

const Book = ({ item, locale }) => (
	<figure className='w-2/3 pb-10'>
		<img src={item.cover.mediaItemUrl} alt='' className='w-full object-cover bg-red-600' />
		<figcaption className='flex flex-col w-full h-48 mt-4'>
			<motion.div
				className='font-mon uppercase text-xl text-blue-500 underline'
				onTap={() => FileSaver.saveAs(item.file, `${slugify(item.title, { lower: true })}${path.extname(item.file)}`)}
				aria-hidden
			>
				{locale === `pt` ? `baixar` : `download`}
			</motion.div>
			<p className='font-mon text-2xl text-white mt-8'>{item.title}</p>
			<a href={item.file} className='sr-only'>
				{locale === `pt` ? `baixar` : `download`}
			</a>
		</figcaption>
	</figure>
);

const A = {
	variants: {
		initial: { opacity: 0 },
		exit: { opacity: 0 },
		enter: { opacity: 1 },
	},
	animate: `enter`,
	initial: `initial`,
	exit: `exit`,
};

const View = styled(motion.div)`
	position: absolute;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: var(--dark-grey);
`;
