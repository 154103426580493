import { createGlobalState } from 'react-hooks-global-state';

export const { useGlobalState } = createGlobalState({
	navigation: null,
	navigation_flat: null,
});

export const getAreaColorByUrl = url => {
	const slug_url = [
		{ slug: `/o-encontro`, color: `#42aed6` },
		{ slug: `/mundo-colonial`, color: `#dd4d3e` },
		{ slug: `/mundo-indigena`, color: `#00b84b` },
		{ slug: `/mundo-contemporaneo`, color: `#ababab` },
		{ slug: `/the-encounter`, color: `#42aed6` },
		{ slug: `/the-colonial-world`, color: `#dd4d3e` },
		{ slug: `/the-indigenous-world`, color: `#00b84b` },
		{ slug: `/contemporary-world`, color: `#ababab` },
		{ slug: `/el-encuentro`, color: `#42aed6` },
		{ slug: `/el-mundo-colonial`, color: `#dd4d3e` },
		{ slug: `/el-mundo-indigena`, color: `#00b84b` },
		{ slug: `/mundo-contemporaneo-2`, color: `#ababab` },
	];

	const item = slug_url.find(e => url.indexOf(e.slug) !== -1);

	let color = `#FFF`;

	if (item) {
		color = item.color;
	}

	return color;
};
