/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { fetcher, QUERY_FICHA_TECNICA } from '../services';
import DOMPurify from 'dompurify';
import useSWR from 'swr';

import * as c from '../components';

const { sanitize } = DOMPurify;

const _data = {
	title: `Ficha<br/>Técnica`,
	blocks: [
		{
			title: `Museu Nacional`,
			items: [
				{
					title: `Reitora da universidade federal do rio de janeiro`,
					content: `Denise Pires de Carvalho`,
				},
				{
					title: `Diretor do Museu Nacional`,
					content: `Alexander Kellner`,
				},
				{
					title: `Diretor adjunto administrativo`,
					content: `Wagner William Martins`,
				},
				{
					title: `Realização`,
					content: `Setor de Etnologia e Etnografia (SEE)`,
				},
				{
					title: `Concepção, curadoria e coordenação geral`,
					content: `Bruno Pacheco de Oliveira<br/>Paulo Perreira`,
				},
				{
					title: `Assistente de curadoria`,
					content: `Wagner William Martins`,
				},
				{
					title: `Pesquisa sonora`,
					content: `Edmundo Marcelo Mendes Pereira`,
				},
				{
					title: `Equipe do Setor de Etnologia e Etnografia`,
					content: `João Pacheco ce Oliveira (Coordenador)<br/>Antonio Carlos de Souza Lima<br/>Crenivaldo Veloso<br/>Michele de Barcelos Agostinho<br/>Paula de Aguiar Silva Azevedo<br/>Rachel Correa Lima<br/>Renata Curcio Valente`,
				},
				{
					title: `Descrição das imagens`,
					content: `Grupo de Trabalho em Acessibilidade do Museu Nacional`,
				},
				{
					title: `Seção de Museologia`,
					content: `Amanda Thomaz Cavalcanti<br/>Edina Maria Pereira Martins<br/>Eduardo Lacerda Gonçalves<br/>Fernanda Pires Santos<br/>Guilherme de Almeida Machado<br/>Juliana Manso Sayão<br/>Marco Aurélio Marques Caldas<br/>Marilene de Oliveira Alves<br/>Paulo Victor Catharino Gitsin<br/>Pedro Henrique de Souza Gomes<br/>Thais Mayumi Pinheiro<br/>Valéria de Azevedo Moreira Rivera`,
				},
				{
					title: `Setor de Museologia/Museu Nacional`,
					content: `Fernanda Pires Santos<br/>Paulo Victor Catharino Gitsin<br/>Amanda<br/>Thais<br/>Guilherme`,
				},
			],
		},

		{
			title: `Arquivo Nacional`,
			items: [
				{
					title: `Diretora-geral`,
					content: `Neide Alves Dias de Sordi`,
				},
				{
					title: `coordenador-geral de administração`,
					content: `Leandro Esteves de Freitas`,
				},
				{
					title: `coordernação-geral de acesso e difusão documental`,
					content: `Luana Farias Sales Marques`,
				},
				{
					title: `coordenação-geral de processamento e preservação do acervo`,
					content: `Aluf Alba Vilar Elias`,
				},
				{
					title: `coordenadora-geral de gestão de documentos`,
					content: `Mariana Barros Meirelles`,
				},
				{
					title: `coordenadora-geral regional no distro federal`,
					content: `Larissa Candida Costa`,
				},
				{
					title: `coordenadora de pesquisa, educação e difusão do acervo`,
					content: `Larissa dos Santos Crativol`,
				},
				{
					title: `Assitente de coordenação`,
					content: `Frederico Bittencourt`,
				},
				{
					title: `Editoração`,
					content: `Mariana Simões`,
				},
				{
					title: `Educação em Arquivos`,
					content: `Claudia Tebyriçá`,
				},
				{
					title: `Programação Visual`,
					content: `Giselle Teixeira`,
				},
				{
					title: `coordenador de preservação de acervo`,
					content: `Tiago Cesar da Silva`,
				},
			],
		},
		{
			title: `Apoio`,
			items: [
				{
					title: ``,
					content: `Apoinme – Articulação dos Povos Indígenas do Nordeste, Minas Gerais e Espírito Santo
`,
				},
				{
					title: ``,
					content: `Associação Amigos do Museu Nacional
`,
				},
				{
					title: ``,
					content: `Fundação`,
				},
				{
					title: ``,
					content: `Laced – Laboratório de Pesquisas em Etnicidade, Cultura e Desenvolvimento `,
				},
				{
					title: ``,
					content: `Conselho Nacional de Desenvolvimento Científico e Tecnológico/CNPq`,
				},
				{
					title: ``,
					content: `Fundação de Apoio à Pesquisa no Estado do Rio de Janeiro/FAPERJ`,
				},
				{
					title: ``,
					content: `CAPES/Edital Memórias Brasileiras`,
				},
				{
					title: ``,
					content: `Universidade Federal da Paraíba`,
				},
			],
		},
		{
			title: `Site`,
			items: [
				{
					title: ``,
					content: `Daniel Morena e Nancy Torres <br/>32Bits Criações Digitais`,
				},
			],
		},
	],
};

export default () => {
	const params = useParams();
	const { pathname } = useLocation();
	const [local, setLocal] = useState(null);
	const [variables, setVariables] = useState(null);
	const { data, error } = useSWR(variables ? [QUERY_FICHA_TECNICA, variables] : null, fetcher);

	const prepare = (text) => {
		return sanitize(text).replace(/(\r\n)/g, `<br/>`);
	};

	useEffect(() => {
		if (params) {
			// const uri = `/${params.locale}/creditos`;
			setVariables({ uri: pathname });
		}
	}, [pathname]);

	useEffect(() => {
		if (data) {
			console.log('...data', data);
			const page = { title: data.page.title, blocks: data.page.ficha_tecnica.block };
			setLocal({ ...page });
		}
	}, [data]);

	return (
		<>
			<motion.article {...A} className='relative w-screen h-auto h-min-screen pb-10 md:pb-20 '>
				{local && (
					<div className='md:ml-72'>
						<h1
							className='text-white font-albra-bd text-5xl px-7 mt-16 md:px-0 md:w-1/2 md:mt-32 md:text-7xl'
							dangerouslySetInnerHTML={{ __html: prepare(local.title) }}
						/>
						<section className='md:flex md:w-full md:min-h-screen'>
							{local.blocks.map((block, index) => (
								<article key={index} className={`md:w-1/2 md:pr-7`}>
									<h2
										className='text-white font-mon text-2xl mt-20 px-7 uppercase md:px-0 '
										dangerouslySetInnerHTML={{ __html: prepare(block.title) }}
									/>
									{block.items.map((item, index) => (
										<React.Fragment key={index}>
											<h3
												className='text-white font-mon-bd text-sm uppercase mt-4 px-7 md:px-0 '
												dangerouslySetInnerHTML={{ __html: prepare(item.title) }}
											/>
											<p
												className='text-white font-mon text-sm mt-2 px-7 md:px-0'
												dangerouslySetInnerHTML={{ __html: prepare(item.content) }}
											/>
										</React.Fragment>
									))}
								</article>
							))}
						</section>
					</div>
				)}
			</motion.article>
			<AnimatePresence exitBeforeEnter>{!data && <c.Loader />}</AnimatePresence>
		</>
	);
};

const A = {
	variants: {
		initial: { opacity: 0 },
		exit: { opacity: 0 },
		enter: { opacity: 1 },
	},
	animate: `enter`,
	initial: `initial`,
	exit: `exit`,
};

const View = styled(motion.div)`
	position: absolute;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: var(--dark-grey);
`;
