import { css } from 'styled-components';

import AlbraRegular from '../assets/fonts/albra/AlbraRegular.otf';
import AlbraMedium from '../assets/fonts/albra/AlbraMedium.otf';
import AlbraBold from '../assets/fonts/albra/AlbraBold.otf';
import AlbraBlack from '../assets/fonts/albra/AlbraBlack.otf';
import MontserratRegular from '../assets/fonts/montserrat/MONTSERRAT-REGULAR.TTF';
import MontserratBold from '../assets/fonts/montserrat/MONTSERRAT-BOLD.TTF';

export default css`
	@font-face {
		font-family: 'AlbraRegular';
		src: url('${AlbraRegular}') format('opentype');
	}
	@font-face {
		font-family: 'AlbraMedium';
		src: url('${AlbraMedium}') format('opentype');
	}
	@font-face {
		font-family: 'AlbraBold';
		src: url('${AlbraBold}') format('opentype');
	}
	@font-face {
		font-family: 'AlbraBlack';
		src: url('${AlbraBlack}') format('opentype');
	}
	@font-face {
		font-family: 'MontserratRegular';
		src: url('${MontserratRegular}');
	}
	@font-face {
		font-family: 'MontserratBold';
		src: url('${MontserratBold}');
	}
`;
