/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useGlobalState, getAreaColorByUrl } from '../state';

import hamburguer from '../assets/icons/hamburguer.svg';
import close from '../assets/icons/fechar.svg';

import { flatListToHierarchical } from '../utils';
import useSWR from 'swr';

import { fetcher, QUERY_MENU } from '../services';

export default () => {
	const history = useHistory();
	const pathnameRef = useRef();
	const { pathname } = useLocation();
	const [list, setList] = useState(null);
	const [main, setMain] = useState(null);
	const [suporte, setSuporte] = useState(null);

	const [, setNavigation] = useGlobalState(`navigation`);
	const [flat, setNavigationFlat] = useGlobalState(`navigation_flat`);

	const { data: data_main, error: error_main } = useSWR([QUERY_MENU, main], fetcher);
	const { data: data_suporte, error: error_suporte } = useSWR([QUERY_MENU, suporte], fetcher);
	console.log('........', error_main, error_suporte, main, suporte);
	useEffect(() => {
		if (data_main && data_suporte) {
			// received nodes
			const nodes = data_main.menu.menuItems.nodes;
			const nodes_s = data_suporte.menu.menuItems.nodes;

			// relative to absolute links
			nodes.map(node => (node.url = node.url.replace(process.env.REACT_APP_ROOT, ``)));

			// do the same on suporte menu, and configure a flag to seaparate both
			const nodes_sm = [];
			nodes_s.map(node =>
				nodes_sm.push({ ...node, url: node.url.replace(process.env.REACT_APP_ROOT, ``), support: true })
			);
			// console.log(nodes_sm);

			// combine both
			let nodes_c = [];

			nodes_c.push(nodes_sm.shift());
			nodes_c.push(nodes_sm.shift());
			nodes_c = [...nodes_c, ...nodes];
			nodes_c = [...nodes_c, ...nodes_sm];

			if (pathname.indexOf(`/pt`) !== -1) {
				nodes_c = [
					...nodes_c,
					{
						id: 'AA',
						label: 'Avaliação/Pesquisa',
						parentId: null,
						support: true,
						url: 'https://docs.google.com/forms/d/e/1FAIpQLScYxRNCyQ70NjxN3tpyk27lz0RNvFH8pbMKsZGHkJ5GReZkTg/viewform',
					},
				];
			}

			// setup flat
			setNavigationFlat(nodes_c);

			// flat to hierachical
			const _list = flatListToHierarchical(nodes_c, { idKey: `id` });

			if (_list) {
				setList(_list);
				setNavigation(_list);
			}
		}
	}, [data_main, data_suporte]);

	// funciona como um 404
	useEffect(() => {
		// if (flat && pathname) {
		// 	const found = flat.find(item => item.url === pathname);
		// }

		if (pathname !== pathnameRef.current) {
			pathnameRef.current = pathname;
			if (pathname.indexOf('/en') !== -1) {
				setMain({ id: 'main_en' });
				setSuporte({ id: 'suporte_en' });
			} else if (pathname.indexOf('/es') !== -1) {
				setMain({ id: 'main_es' });
				setSuporte({ id: 'suporte_es' });
			} else if (pathname.indexOf('/pt') !== -1) {
				setMain({ id: 'main' });
				setSuporte({ id: 'suporte' });
			} else {
				setMain({ id: 'main' });
				setSuporte({ id: 'suporte' });
			}
		}
	}, [pathname, flat]);

	const [open, setOpen] = useState(false);

	const _open = () => {
		setOpen(true);
	};

	const _close = e => {
		setOpen(false);
	};

	return (
		<>
			<motion.nav
				{...A.view}
				animate={open ? 'enter' : 'exit'}
				className='fixed top-0 w-screen h-screen min-h-screen overflow-x-hidden overflow-y-scroll z-50 bg-cinza'>
				<ul className='relative w-screen h-full min-h-screen p-10 px-12 mt-10'>
					{pathname.indexOf(`/en`) === -1 && (
						<motion.li
							className='font-mon mb-2 uppercase text-xs font-albra text-white mt-4'
							onTap={e => {
								setTimeout(() => {
									window.location.href = `/en`;
								}, 500);
							}}>
							english version
						</motion.li>
					)}
					{pathname.indexOf(`/pt`) === -1 && (
						<motion.li
							className='font-mon mb-2 uppercase text-xs font-albra text-white mt-4'
							onTap={e => {
								_close();
								setTimeout(() => {
									window.location.href = `/pt`;
								}, 500);
							}}>
							versão em português
						</motion.li>
					)}
					{pathname.indexOf(`/es`) === -1 && (
						<motion.li
							className='font-mon mb-2 uppercase text-xs font-albra text-white mt-4'
							onTap={e => {
								_close();
								setTimeout(() => {
									window.location.href = `/es`;
								}, 500);
							}}>
							versión en español
						</motion.li>
					)}
					<div className='mb-8' />
					{list?.map(item => {
						return (
							<>
								{item.children.length === 0 ? (
									<motion.li className='text-2xl font-albra text-white mt-4' onTap={() => _close()}>
										<Link to={item.url}>{item.label}</Link>
									</motion.li>
								) : (
									<motion.li
										className='text-4xl font-albra-bd text-white mt-7'
										style={{ color: getAreaColorByUrl(item.url) }}
										onTap={() => _close()}>
										<Link to={item.url}>{item.label}</Link>
									</motion.li>
								)}
								{item.children?.map(subitem => {
									return (
										<motion.li
											className='text-xl font-albra text-white mt-2 ml-7'
											style={{ color: getAreaColorByUrl(item.url) }}
											onTap={() => _close()}>
											<Link to={subitem.url}>{subitem.label}</Link>
										</motion.li>
									);
								})}
							</>
						);
					})}
					<li className='h-10' />
				</ul>
			</motion.nav>

			{open ? (
				<img src={close} alt='' className='block fixed top-2 left-2 w-12 h-12 z-50' onClick={_close} />
			) : (
				<img src={hamburguer} alt='' className='block fixed top-2 left-2 w-12 h-12 z-50' onClick={_open} />
			)}
		</>
	);
};

const A = {
	view: {
		variants: {
			initial: { x: `-100vw` },
			exit: { x: `-100vw`, transition: { duration: 0.4, delay: 0.25 } },
			enter: { x: `0vw`, transition: { duration: 0.4 } },
		},
		initial: `initial`,
		exit: `exit`,
	},
};
