import { createGlobalStyle, css } from 'styled-components';
import fonts from './fonts.js';
import type from './type.js';
import reset from './reset.js';

export { ThemeProvider } from 'styled-components';

export const theme = {
	colors: {
		grayDark: `#2d2d2d`,
		grayDarkTransp: `rgba(45, 45, 45, 0.9)`,
		grayDarkGradient: `linear-gradient(rgba(45, 45, 45, 1), rgba(45, 45, 45, 0.5), rgba(45, 45, 45, 0))`,
		grayDarkGradientHorizontal: `linear-gradient(to right, rgba(45, 45, 45, 1), rgba(45, 45, 45, 0), rgba(45, 45, 45, 0), rgba(45, 45, 45, 1))`,
		encontro: `#42aed6`,
		colonizacao: `#dd4d3e`,
		indigena: `#00b84b`,
		contemporaneo: `#ababab`,
	},
};

export const GlobalStyle = createGlobalStyle`
    // ${fonts}
    // ${type}

    * {
        box-sizing: border-box;
        // cursor:none;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    *[data-cursor]{
        pointer-events:auto;
    }

    img {
        user-drag: none;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }

    body {
        display: flex;
        flex-direction: column;
        background-color: ${(props) => props.theme.colors.grayDark};
        line-height: 1;
    }

    .no-scrollbars, {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .no-scrollbars::-webkit-scrollbar {
        display: none;
    }

    ::-webkit-scrollbar {
        width: 0px; /* Remove scrollbar space */
        background: transparent; /* Optional: just make scrollbar invisible */
        display:none;
    }

    ::-webkit-scrollbar-thumb {
        background:transparent;
    }
`;
