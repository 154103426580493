/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useGlobalState, getAreaColorByUrl } from '../state';

import hamburguer from '../assets/icons/hamburguer.svg';
import close from '../assets/icons/fechar.svg';

import { flatListToHierarchical } from '../utils';
import useSWR from 'swr';

import { fetcher, QUERY_MENU } from '../services';

export default () => {
	const history = useHistory();
	const pathnameRef = useRef();
	const { pathname } = useLocation();

	const [list, setList] = useState(null);
	const [main, setMain] = useState(null);
	const [suporte, setSuporte] = useState(null);

	const [, setNavigation] = useGlobalState(`navigation`);
	const [flat, setNavigationFlat] = useGlobalState(`navigation_flat`);

	const { data: data_main, error: error_main } = useSWR([QUERY_MENU, main], fetcher);
	const { data: data_suporte, error: error_suporte } = useSWR([QUERY_MENU, suporte], fetcher);

	useEffect(() => {
		if (data_main && data_suporte) {
			// received nodes

			const nodes = data_main.menu.menuItems.nodes;
			const nodes_s = data_suporte.menu.menuItems.nodes;

			// relative to absolute links
			nodes.map(node => (node.url = node.url.replace(process.env.REACT_APP_ROOT, ``)));

			// do the same on suporte menu, and configure a flag to seaparate both
			const nodes_sm = [];
			nodes_s.map(node =>
				nodes_sm.push({ ...node, url: node.url.replace(process.env.REACT_APP_ROOT, ``), support: true })
			);

			// combine both
			let nodes_c = [];

			nodes_c.push(nodes_sm.shift());
			nodes_c.push(nodes_sm.shift());
			nodes_c = [...nodes_c, ...nodes];
			nodes_c = [...nodes_c, ...nodes_sm];

			if (pathname.indexOf(`/pt`) !== -1) {
				nodes_c = [
					...nodes_c,
					{
						id: 'AA',
						label: 'Avaliação/Pesquisa',
						parentId: null,
						support: true,
						url: 'https://docs.google.com/forms/d/e/1FAIpQLScYxRNCyQ70NjxN3tpyk27lz0RNvFH8pbMKsZGHkJ5GReZkTg/viewform',
					},
				];
			}

			// setup flat
			setNavigationFlat(nodes_c);

			// flat to hierachical
			const _list = flatListToHierarchical(nodes_c, { idKey: `id` });

			if (_list) {
				setList(_list);
				setNavigation(_list);
			}
		}
	}, [data_main, data_suporte]);

	// funciona como um 404
	useEffect(() => {
		// if (flat && pathname) {
		// 	const found = flat.find(item => item.url === pathname);
		// 	if (!found) {
		// 		//history.push(`/pt`);
		// 	}
		// }

		if (pathname !== pathnameRef.current) {
			pathnameRef.current = pathname;
			if (pathname.indexOf('/en') !== -1) {
				setMain({ id: 'main_en' });
				setSuporte({ id: 'suporte_en' });
			} else if (pathname.indexOf('/es') !== -1) {
				setMain({ id: 'main_es' });
				setSuporte({ id: 'suporte_es' });
			} else if (pathname.indexOf('/pt') !== -1) {
				setMain({ id: 'main' });
				setSuporte({ id: 'suporte' });
			} else {
				setMain({ id: 'main' });
				setSuporte({ id: 'suporte' });
			}
		}
	}, [pathname, flat]);

	const [open, setOpen] = useState(false);

	const _open = () => {
		setOpen(true);
	};

	const _close = e => {
		setOpen(false);
		window.restoreCursor();
	};

	return (
		<>
			<View
				{...a.view}
				animate={open ? 'enter' : 'exit'}
				onTap={_close}
				onHoverStart={() => window.changeCursor(`close`)}
				onHoverEnd={() => window.restoreCursor()}>
				<ScrollPane>
					<div className='absolute top-0 flex flex-none' style={{ top: '10vh', left: '14.4vw' }}>
						{pathname.indexOf('/en') === -1 && (
							<motion.div
								className='lng flex-none bg-'
								onHoverStart={() => window.changeCursor(`select`)}
								onHoverEnd={() => window.restoreCursor()}
								style={{ pointerEvents: `auto` }}
								onTap={e => {
									_close();
									setTimeout(() => {
										window.location.href = `/en`;
									}, 500);
								}}>
								english version
							</motion.div>
						)}
						{pathname.indexOf('/es') === -1 && (
							<motion.div
								className='lng flex-none'
								style={{ pointerEvents: `auto` }}
								onHoverStart={() => window.changeCursor(`select`)}
								onHoverEnd={() => window.restoreCursor()}
								onTap={e => {
									_close();
									setTimeout(() => {
										window.location.href = `/es`;
									}, 500);
								}}>
								versión en español
							</motion.div>
						)}
						{pathname.indexOf('/pt') === -1 && pathname !== '/' && (
							<motion.div
								className='lng flex-none'
								style={{ pointerEvents: `auto` }}
								onHoverStart={() => window.changeCursor(`select`)}
								onHoverEnd={() => window.restoreCursor()}
								onTap={e => {
									_close();
									setTimeout(() => {
										window.location.href = `/pt`;
									}, 500);
								}}>
								versão em português
							</motion.div>
						)}
					</div>
					<motion.ul>
						<AnimatePresence>
							{open && list?.map((item, i) => <Item key={item.id} custom={i} {...item} />)}
						</AnimatePresence>
					</motion.ul>
				</ScrollPane>
			</View>

			{!open && (
				<motion.img
					src={hamburguer}
					{...a.hamb}
					onTap={_open}
					onHoverStart={() => window.changeCursor(`select`)}
					onHoverEnd={() => window.restoreCursor()}
					alt=''
					style={{ zIndex: 1000 }}
					className='fixed left-7 top-4 w-12 h-12'
				/>
			)}
			{open && (
				<motion.img
					src={close}
					{...a.hamb}
					onTap={_close}
					onHoverStart={() => window.changeCursor(`select`)}
					onHoverEnd={() => window.restoreCursor()}
					alt=''
					style={{ zIndex: 1000 }}
					className='fixed left-7 top-4 w-12 h-12'
				/>
			)}
		</>
	);
};

const Item = ({ label, children, custom, support, url }) => {
	const h = useHistory();
	const [color, setColor] = useState(`#aaa`);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		setColor(getAreaColorByUrl(url));
	}, []);

	const toggle = (e, support) => {
		if (support) {
			if (url.indexOf('https://') === 0) {
				window.open(url, '_target');
			} else {
				h.push(url);
			}
		} else {
			e.stopImmediatePropagation();

			if (children?.length) {
				setOpen(!open);
			} else {
				if (url.indexOf('https://') === 0) {
					window.open(url, '_target');
				}
			}
		}
	};

	return (
		<>
			<ItemView
				{...a.item}
				className={!support ? `type-large-title` : `type-small-title`}
				style={{ color }}
				custom={custom}
				onTap={e => toggle(e, support)}
				data-url={url}
				whileHover={{ scale: 1.1, originX: 0, originY: 0.5 }}
				onHoverStart={() => window.changeCursor(`select`)}
				onHoverEnd={() => window.changeCursor(`close`)}>
				{label}
			</ItemView>
			<AnimatePresence>
				{open && children && (
					<>
						<SubitemsWrapper {...a.subitemsWrapper}>
							{children?.map((subitem, i) => (
								<Subitem key={`${subitem.id}`} custom={i} {...subitem} color={color} />
							))}
						</SubitemsWrapper>
					</>
				)}
			</AnimatePresence>
		</>
	);
};

const Subitem = ({ label, custom, url, color }) => {
	const h = useHistory();

	const go = () => {
		h.push(url);
	};

	return (
		<SubitemView
			key={1}
			whileHover={{ scale: 1.2, originX: 0, originY: 0.5 }}
			custom={custom}
			{...a.subitem}
			className={`type-small-title`}
			color={color}
			onHoverStart={() => window.changeCursor(`select`)}
			onHoverEnd={() => window.changeCursor(`close`)}
			onTap={go}>
			{label}
		</SubitemView>
	);
};

const a = {
	view: {
		variants: {
			initial: { opacity: 0, x: `-100vw` },
			exit: { opacity: 0, x: `-100vw`, transition: { duration: 0.4, delay: 0.25 } },
			enter: { opacity: 1, x: `0vw`, transition: { duration: 0.4 } },
		},
		initial: `initial`,
		exit: `exit`,
	},
	item: {
		variants: {
			initial: { opacity: 0, x: -200 },
			exit: custom => ({
				opacity: 0,
				x: -200,
				transition: { delay: 0.1 * custom, ease: `anticipate`, duration: 0.4 },
			}),
			enter: custom => ({
				opacity: 1,
				x: 0,
				transition: { delay: 0.2 + 0.1 * custom, ease: `anticipate`, duration: 0.4 },
			}),
		},
		initial: `initial`,
		exit: `exit`,
		animate: `enter`,
	},
	subitemsWrapper: {
		variants: {
			initial: { height: 0, marginBottom: 0, marginTop: 0 },
			exit: { height: 0, marginBottom: 0, marginTop: 0 },
			enter: { height: `auto`, marginBottom: `5vw`, marginTop: `1.5vw`, transition: { duration: 0.35 } },
		},
		initial: `initial`,
		exit: `exit`,
		animate: `enter`,
	},
	subitem: {
		variants: {
			initial: { opacity: 0, x: 50 },
			exit: { opacity: 0, x: 50, transition: { duration: 0.25 } },
			enter: custom => ({ opacity: 1, x: 0, transition: { delay: 0.07 * custom, duration: 0.25 } }),
		},
		initial: `initial`,
		exit: `exit`,
		animate: `enter`,
	},
	hamb: {
		variants: {
			initial: {
				opacity: 1,
				display: 'block',
			},
			opened: {
				opacity: 0,
				display: 'none',
			},
			closed: {
				opacity: 1,
				display: 'block',
			},
		},
	},
	close: {
		variants: {
			initial: {
				opacity: 0,
			},
			opened: {
				opacity: 1,
			},
			closed: {
				opacity: 0,
			},
		},
	},
};

const View = styled(motion.nav)`
	position: fixed;
	left: 0;
	top: 0;
	background-color: ${props => props.theme.colors.grayDark};
	width: 100vw;
	min-height: 100vh;
	z-index: 1000;
	// padding-top: 12vw;
	// padding-bottom: 10vw;

	user-select: none;
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-o-user-select: none;

	ul,
	li {
		display: block;
	}

	.lng {
		position: relative;
		width: auto;
		margin-left: 1rem;

		padding: 1vh 0;
		color: white;
		font-family: MontserratRegular;
		font-size: 1vw;
		text-transform: uppercase;
		pointer-events: auto;

		z-index: 10;

		user-select: none;
		-moz-user-select: none;
		-khtml-user-select: none;
		-webkit-user-select: none;
		-o-user-select: none;
	}
	.lng:fistchild {
		margin-left: 0;
	}
`;

const ScrollPane = styled(motion.div)`
	position: absolute;
	padding: 10vw 0vw;
	height: 100%;
	width: 100%;
	overflow-y: auto;
`;

const ItemView = styled(motion.li)`
	width: 70vw;
	margin-top: 1.736vw;
	color: white;
	margin-left: 15vw;
`;

const SubitemsWrapper = styled(motion.ul)`
	margin-top: 1.5vw;
	margin-bottom: 5vw;
	overflow: hidden;
	margin-left: 15vw;
`;

const SubitemView = styled(motion.li)`
	box-sizing: border-box;
	width: 70vw;
	height: 3.472vw;
	margin-top: 0.694vw;
	color: ${p => p.color};
	padding-top: 8px;
`;

const Burguer = styled(motion.img)`
	position: fixed;
	left: 2vw;
	top: 1.5vw;
	width: 3vw;
	height: 3vw;
	z-index: 1000;
	object-fit: cover;
`;
