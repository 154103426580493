import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

export default ({ image = ``, caption, onClose }) => {
	const [visible, setVisible] = useState(false);
	const [y, setY] = useState(0);

	useEffect(() => {
		const _visible = image !== '';

		if (_visible) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = '';
		}

		setVisible(_visible);
	}, [image]);

	return (
		<View
			variants={{
				visible: { opacity: 1, display: `flex`, pointerEvents: `auto`, transition: { when: `beforeChildren` } },
				hidden: { opacity: 0, transitionEnd: { pointerEvents: `none`, display: `none` } },
			}}
			animate={visible ? 'visible' : 'hidden'}
			initial='hidden'
			exit='hidden'
			onTap={onClose}
			// data-cursor='close'
			onHoverStart={() => window.changeCursor(`close`)}
			onHoverEnd={() => window.restoreCursor()}>
			<AnimatePresence>
				{visible && (
					<>
						<Image
							src={image}
							initial={{ scale: 0, opacity: 0 }}
							animate={{ scale: 1, opacity: 1, transition: { delay: 0.2, stiffness: 100, dumping: 100 } }}
							exit={{ scale: 0, opacity: 0 }}
							transition={{ stiffness: 200, dumpig: 100 }}
						/>
						<Caption
							initial={{ y: 20, opacity: 0 }}
							animate={{ y: 0, opacity: 1, transition: { delay: 0.5, stiffness: 100, dumping: 100 } }}
							exit={{ opacity: 0 }}
							className='type-caption type-white'>
							Nullam justo est, imperdiet sit amet dolor non, aliquet ultricies neque.
						</Caption>
					</>
				)}
			</AnimatePresence>
		</View>
	);
};

const View = styled(motion.div)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	opacity: 0;
	z-index: 1000;
	pointer-events: none;

	background-color: ${(props) => props.theme.colors.grayDarkTransp};
`;

const Image = styled(motion.div)`
	display: block;
	// position: relative;
	// left: 5vw;
	// top: 5vh;
	background: url(${(props) => props.src}) no-repeat center center;
	background-size: contain;
	width: 90vw;
	height: 80vh;
`;

const Caption = styled(motion.p)`
	width: 50vw;
	height: auto;
	// background-color: red;
	text-align: center;
	margin-top: 2vw;
`;

const an = {
	variants: {
		initial: {
			display: `none`,
			opacity: 0,
		},
		open: {
			display: `block`,
			opacity: 1,
		},
		close: {
			display: `none`,
			opacity: 0,
		},
	},
	initial: `initial`,
};
