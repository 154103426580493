/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
import React from 'react';
import { motion } from 'framer-motion';
import { Switch, Route } from 'react-router-dom';
import styled from 'styled-components';

import * as c from './components';
import * as r from './routes';

export default () => {
	return (
		<>
			<header>
				<c.ScrollProgress />
				<c.Mobile>
					<c.NavigationMobile />
				</c.Mobile>
				<c.NotMobile>
					<c.Navigation />
				</c.NotMobile>
			</header>
			<Switch>
				<Route exact path={['/:locale/apresentacao', '/:locale/presentation', '/:locale/presentacion']}>
					<main>
						<r.Apresentacao />
					</main>
				</Route>
				<Route exact path={['/:locale/texto-do-curador', '/:locale/curators-foreword/', '/:locale/texto-del-curador/']}>
					<main>
						<r.Curador />
					</main>
				</Route>
				<Route exact path={['/:locale/creditos', '/:locale/production', '/:locale/produccion']}>
					<main>
						<r.Ficha />
					</main>
				</Route>
				<Route exact path={['/:locale/conteudos-extras', '/:locale/extra-content', '/:locale/contenido-extra']}>
					<main>
						<r.Extra />
					</main>
				</Route>
				<Route exact path={[`/`, '/:locale/:section?']}>
					<main>
						<r.Principal />
					</main>
				</Route>
				<Route
					path={[
						'/:locale/mundo-contemporaneo/os-povos',
						'/:locale/contemporary-world/indigenous-groups',
						'/:locale/mundo-contemporaneo-2/grupos-indigenas',
					]}>
					<r.Povos />
				</Route>
				<Route path={['/:locale/:section/:page/:type?/:modal?/:index?']}>
					<r.Internal />
				</Route>
			</Switch>
			<c.NotMobile>
				<c.Breadcrumb />
				<c.Mouse />
			</c.NotMobile>
			<c.Mobile>
				<div
					className='fixed left-0 top-0 w-screen h-40 bg-gradient-to-b opacity-50 from-black to-transparent pointer-events-none'
					style={{ zIndex: 10 }}
				/>
			</c.Mobile>
			<c.Sound />
		</>
	);
};

// const Lang = styled(motion.div)`
// 	position: fixed;
// 	top: 3vh;
// 	right: 8vw;

// 	padding: 1vh 0;
// 	color: white;
// 	font-family: MontserratRegular;
// 	font-size: 0.8vw;
// 	text-transform: uppercase;
// 	pointer-events: auto;

// 	z-index: 10;

// 	user-select: none;
// 	-moz-user-select: none;
// 	-khtml-user-select: none;
// 	-webkit-user-select: none;
// 	-o-user-select: none;

// 	curso: pointer;

// 	filter: drop-shadow(0 0 0.75rem black);
// `;
