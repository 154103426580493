import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { useEventListener } from '../hooks';
import { motion, useMotionValue } from 'framer-motion';
import icMais from '../assets/icons/mais.svg';
import icScroll from '../assets/icons/scroll.svg';
import icClose from '../assets/icons/fechar.svg';
import icNext from '../assets/icons/avancar.svg';
import icPrevious from '../assets/icons/voltar.svg';
import icSelect from '../assets/icons/select.svg';
import icUp from '../assets/icons/up.svg';
import styled from 'styled-components';

const dot = 7;
const arrow = 40;
const color = `white`;
const cursors = {
	close: icClose,
	mais: icMais,
	scroll: icScroll,
	next: icNext,
	previous: icPrevious,
	up: icUp,
	select: icSelect,
};

export default () => {
	const x = useMotionValue(0);
	const y = useMotionValue(0);

	const [visible, setVisible] = useState(false);
	const [over, setOver] = useState(false);
	const [cursor, setCursor] = useState(null);
	const [arrowScale, setArrowScale] = useState(1);
	const [arrowOpacity, setArrowOpacity] = useState(1);

	useEffect(() => {
		window.changeCursor = (type) => {
			if (type) {
				const cursor = cursors[type];
				const scale = type === `select` ? 1.3 : 1.8;

				setArrowOpacity(type === `scroll` || type === `next` || type === `previous` ? 0 : 1);

				setOver(true);
				setCursor(cursor);
				setArrowScale(scale);
			} else {
				window.restoreCursor();
			}
		};

		window.restoreCursor = () => {
			setArrowOpacity(1);
			setOver(false);
			setArrowScale(1);
		};

		window.restoreCursor();

		return () => {
			window.changeCursor = null;
			window.restoreCursor = null;
		};
	}, []);

	const onMouseEnter = (e) => {
		setVisible(true);
	};

	const onMouseLeave = (e) => {
		setVisible(false);
	};

	const onMouseMove = useCallback(({ clientX, clientY }) => {
		x.set(clientX);
		y.set(clientY);

		if (!visible) {
			setVisible(true);
		}
	}, []);

	useEventListener('mousemove', onMouseMove, document);
	useEventListener('mouseenter', onMouseEnter, document);
	useEventListener('mouseleave', onMouseLeave, document);

	return (
		<View style={{ x, y }} animate={{ opacity: visible ? 1 : 0 }}>
			<Mouse size={dot} color={color} animate={{ opacity: over ? 0 : 1, scale: over ? 0 : 1 }} />
			<Arrow size={arrow} color={color} animate={{ scale: arrowScale, opacity: arrowOpacity }} />
			{cursor && <Icon src={cursor} size={arrow} animate={{ opacity: over && visible ? 1 : 0, scale: over ? 1 : 0 }} />}
		</View>
	);
};

const View = styled(motion.div)`
	pointer-events: none;
	position: fixed;
	left: 0;
	top: 0;
	width: 70px;
	height: 70px;
	margin: -35px 0 0 -35px;
	point-events: none;
	z-index: 1000;
	filter: drop-shadow(0 0 10px black);
`;

const Mouse = styled(motion.div)`
	pointer-events: none;
	position: absolute;
	left: ${(props) => (70 - props.size) / 2}px;
	top: ${(props) => (70 - props.size) / 2}px;
	width: ${(props) => props.size}px;
	height: ${(props) => props.size}px;
	border-radius: 50%;
	background-color: ${(props) => props.color};
`;

const Arrow = styled(motion.div)`
	pointer-events: none;
	position: absolute;
	left: 15px;
	top: 15px;
	box-sizing: border-box;
	width: ${(props) => props.size}px;
	height: ${(props) => props.size}px;
	border: 2px solid ${(props) => props.color};
	border-radius: 50%;
`;

const Icon = styled(motion.img)`
	pointer-events: none;
	position: absolute;
	left: 15px;
	top: 15px;
	width: ${(props) => props.size}px;
	height: ${(props) => props.size}px;
	background-color: trannsparent;
	border-radius: 50%;
	z-index: 1000;
`;
