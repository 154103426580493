import React, { useState, useEffect, useReducer, useRef } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as PreviousIcon } from '../assets/icons/voltar.svg';
import { ReactComponent as NextIcon } from '../assets/icons/avancar.svg';
import { ReactComponent as CloseIcon } from '../assets/icons/fechar.svg';
import { useLocation } from 'react-router-dom';
import path from 'path-browserify';
import Loader from '../assets/icons/loader.gif';
import isArray from 'lodash/isArray';

import * as c from '../components';

const reducer = (state, action) => {
	switch (action.type) {
		case 'init': {
			let index = state.index;

			if (index < 0) {
				index = 0;
			}

			if (index > state.data.length - 1) {
				index = state.data.length - 1;
			}

			// const item = isArray(state.data) ? state.data[index] : state.data;
			const item = state.data[index];
			// console.log(item, index, isArray(state.data));
			const src = item.img.mediaItemUrl;

			const { width, height } = item.img.mediaDetails;
			const { title, imgCredits: credits, imgAuthor: author, imgCopyright: copyright, description: description, backgroundColor } = item;

			return { ...state, direction: -1, width, height, index, src, title, credits, author, copyright, description, backgroundColor };
		}
		case 'previous': {
			let index = state.index - 1;

			if (index < 0) {
				index = 0;
			}

			const item = state.data[index];
			const src = item.img.mediaItemUrl;
			const { width, height } = item.img.mediaDetails;
			const { title, imgCredits: credits, imgAuthor: author, imgCopyright: copyright, description: description, backgroundColor } = item;

			window.history.replaceState({}, '', `${action.url}/${index}`);

			return { ...state, direction: 1, width, height, index, src, title, credits, author, copyright, description, backgroundColor };
		}
		case 'next': {
			let index = parseInt(state.index) + 1;

			if (index > state.data.length - 1) {
				index = state.data.length - 1;
			}
			const item = state.data[index];

			const src = item.img.mediaItemUrl;
			let { width, height } = item.img.mediaDetails;
			const { title, imgCredits: credits, imgAuthor: author, imgCopyright: copyright, description: description, backgroundColor } = item;

			window.history.replaceState({}, '', `${action.url}/${index}`);

			return { ...state, direction: -1, index, src, title, credits, author, copyright, description, backgroundColor };
		}
		default: {
			return { ...state };
		}
	}
};

export default ({ id, data, index = 0, onClose }) => {
	const [loading, setLoading] = useState(true);
	const { pathname } = useLocation();
	const [state, dispatch] = useReducer(reducer, {
		index,
		data,
		src: null,
		title: null,
		credits: null,
		author: null,
		copyright: null,
		description: null,
		width: 0,
		height: 0,
		direction: -1,
		background: `#000000`,
	});

	useEffect(() => {
		if (data) {
			dispatch({ type: `init` });
		}
	}, [data]);

	const handlePrevious = () => {
		setLoading(true);
		dispatch({ type: `previous`, url: path.dirname(window.location.pathname) });
	};

	const handleNext = () => {
		setLoading(true);
		dispatch({ type: `next`, url: path.dirname(window.location.pathname) });
	};

	return (
		<View>
			<AnimatePresence exitBeforeEnter initial={false} custom={state.direction}>
				<motion.section key={state.src} {...A.img} custom={state.direction} className='w-screen h-screen flex flex-col'>
					<motion.figure
						initial={`loading`}
						animate={loading ? 'loading' : 'done'}
						variants={{ loading: { opacity: 0 }, done: { opacity: 1 } }}
						className='w-sceen h-5/6 py-6 mt-2'>
						<img src={state.src} onLoad={() => setTimeout(() => setLoading(false), 500)} alt='' className='w-full h-full object-contain' />
					</motion.figure>
					<motion.figcaption
						animate={{ opacity: 1, transition: { delay: 0.5 } }}
						initial={{ opacity: 0 }}
						className='w-screen h-1/6 text-center text-white px-2 md:p-4 '>
						<span className='block text-mon text-sm md:text-2xl'>{state.title}</span>
						<span className='block text-mon text-xs md:text-sm text-gray-200'>{state.author}</span>
						<span className='block text-mon text-xs md:text-sm italic text-gray-200'>{state.credits}</span>
						<span className='block text-mon text-xs md:text-sm italic text-gray-200'>{state.copyright}</span>
					</motion.figcaption>
				</motion.section>
			</AnimatePresence>
			<AnimatePresence exitBeforeEnter>
				{loading && (
					<motion.img
						src={Loader}
						initial={{ opacity: 0 }}
						animate={{ opacity: 1, transition: { delay: 1.5 } }}
						exit={{ opacity: 0 }}
						className='absolute left-1/2 top-1/2 -mt-10 -ml-10 w-20 h-20'
					/>
				)}
			</AnimatePresence>

			<div
				className='navigation'
				className='absolute w-screen h-screen left-0 top-0 flex flex-row justify-between items-end md:items-center -px-10 pb-0 md:p-20 pointer-events-none'>
				{state.index > 0 && (
					<motion.div
						className='md:w-20 md:h-20 transform scale-50 md:scale-100 pointer-events-auto'
						onTap={handlePrevious}
						onHoverStart={() => window.changeCursor(`select`)}
						onHoverEnd={() => window.restoreCursor()}>
						<PreviousIcon />
					</motion.div>
				)}
				<motion.div className='center' onHoverStart={() => window.restoreCursor()} />
				<div className='absolute top-0 right-0 w-12 h-12 md:w-24 md:h-24 grid place-items-center pointer-events-auto' onClick={() => onClose()}>
					<CloseIcon className='w-12 -mt-4 md:w-24 ' />
				</div>
				{state.index < state.data?.length - 1 && (
					<motion.div
						className='right'
						onTap={handleNext}
						className='md:w-20 md:h-20 transform scale-50 md:scale-100 pointer-events-auto'
						onHoverStart={() => window.changeCursor(`select`)}
						onHoverEnd={() => window.restoreCursor()}>
						<NextIcon />
					</motion.div>
				)}
			</div>
		</View>
	);
};

const Image = ({ src }) => {
	const [loading, setLoading] = useState(true);
	return (
		<>
			<AnimatePresence>
				{loading && (
					<motion.div
						animate={{ opacity: 1, transition: { delay: 0.5 } }}
						initial={{ opacity: 0 }}
						exit={{ opacity: 0 }}
						style={{
							width: `100vw`,
							height: `100vh`,
							position: `fixed`,
							left: 0,
							top: 0,
							display: `flex`,
							alignItems: `center`,
							justifyContent: `center`,
						}}>
						<img src={Loader} />
					</motion.div>
				)}
			</AnimatePresence>
			<ImageView>
				<motion.img src={src} animate={{ opacity: loading ? 0 : 1 }} onLoad={() => setLoading(false)} />;
			</ImageView>
		</>
	);
};

const A = {
	img: {
		variants: {
			inital: (custom) => ({ opacity: 0, x: -100 * custom }),
			enter: (custom) => ({ opacity: 1, x: 0 }),
			exit: (custom) => ({
				opacity: 0,
				x: 100 * custom,
			}),
		},
		transition: {
			x: { type: 'spring', stiffness: 300, damping: 30 },
			opacity: { duration: 0.2 },
		},
		exit: `exit`,
		animate: `enter`,
	},
	wrapper: {
		variants: {
			enter: { opacity: 1 },
			exit: { opacity: 0 },
		},
		exit: `exit`,
		enter: `enter`,
		initial: `exit`,
	},
};

const View = styled(motion.div)`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: ${(props) => props.theme.colors.grayDarkTransp};
	z-index: 1000;
	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;

	pointer-events: none;

	.disable {
		pointer-events: none;
	}

	.navigation {
		position: absolute;
		left: 0;
		top: 0;
		display: flex;
		flex-direction: row;
		width: 100vw;
		height: 100vh;
		z-index: 1;

		pointer-events: auto;

		.left {
			flex: 1;
			background-color: rgba(255, 0, 0, 0);
			display: flex;
			align-items: center;
			justify-content: flex-start;
			padding-left: 2.5vw;
			padding-top: 65vh;
		}

		.center {
			flex: 5;
			background-color: rgba(0, 0, 0, 0);
		}

		.right {
			flex: 1;
			background-color: rgba(255, 0, 0, 0);
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding-right: 2.5vw;
			padding-top: 65vh;
		}
	}
`;

const ImageView = styled(motion.div)`
	width: 90vw;
	height: 75vh;
	margin-top: 5vh;

	img {
		display: block;
		border: 0;
		margin: auto;
		max-width: 90vw;
		max-height: 80vh;
		opacity: 0;
		object-fit: contain;
	}
`;

const InfoView = styled(motion.div)`
	position: absolute;
	top: 88vh;
	left: 22.25vw;
	position: absolute;
	top: 88vh;
	left: 22.25vw;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: white;
	width: 55vw;
	line-height: 1.3;
	text-align: center;

	.title {
		font-family: MontserratRegular;
		font-size: 28px;
	}
	.author,
	.credits,
	.copyright {
		font-family: MontserratRegular;
		font-size: 16px;
	}

	.credits,
	.copyright {
		font-style: italic;
		font-size: 12px;
	}
`;
