/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { useHistory, useParams, useLocation, Link } from 'react-router-dom';
import { fetcher, QUERY_OS_POVOS } from '../services';
import useSWR from 'swr';
import shuffle from 'lodash/shuffle';
import orderBy from 'lodash/orderBy';
import icAv from '../assets/icons/avancar.svg';

import * as c from '../components';

export default () => {
	const params = useParams();
	const control = useAnimation();
	const { pathname } = useLocation();
	const [img, setImg] = useState();
	const [local, setLocal] = useState(null);
	const [variables, setVariables] = useState(null);
	const { data, error } = useSWR(variables ? [QUERY_OS_POVOS, variables] : null, fetcher);

	useEffect(() => {
		if (pathname) {
			console.log(pathname);
			setVariables({ uri: pathname });
		}
	}, [pathname]);

	useEffect(() => {
		if (data) {
			console.log('data', data);
			const povos = data.page.povos.list.map(povo => ({
				label: povo.label,
				url: povo.url.url,
				image: povo.image.mediaItemUrl,
			}));

			const list = shuffle([...povos]);
			setImg(list[0].image);
			setLocal([...list]);
		}
	}, [data]);

	useEffect(() => {
		control.start({ opacity: [0, 0.5], transition: { duration: 0.2 } });
	}, [img]);

	const handleOrderTap = () => {
		const list = orderBy([...local], 'label', 'asc');
		setLocal([...list]);
	};

	const handleHover = image => {
		setImg(image);
	};

	return (
		<>
			<motion.article className='p-7  mt-16 md:mt-0 md:p-0 md:relative w-full h-auto h-min-screen md:pt-40'>
				<motion.button
					className='md:absolute md:left-72 bg-transparent font-mon uppercase md:text-2xl text-gray-400 '
					onTap={handleOrderTap}>
					A-Z
				</motion.button>
				<c.Desktop>
					<figure className='fixed left-72 ml-40 mt-20 bg-gray-800' style={{ width: `46.875vw`, height: `35.156vw` }}>
						<motion.img
							src={img}
							alt=''
							initial={{ opacity: 0 }}
							animate={control}
							className='w-full h-full object-cover opacity-50'
						/>
					</figure>
				</c.Desktop>
				<ul className='mt-5 pb-10 md:ml-72 md:mt-10 md:w-7/12 md:min-h-screen md:pb-72'>
					{local?.map(item => (
						<Item key={item.url} {...item} onHover={handleHover} />
					))}
				</ul>
			</motion.article>
			<AnimatePresence exitBeforeEnter>{!data && <c.Loader />}</AnimatePresence>
		</>
	);
};

const Item = ({ label, url, image, onHover }) => {
	const [over, setOver] = useState(false);

	const handleHoverStart = () => {
		setOver(true);
		onHover(image);
		window.changeCursor(`mais`);
	};

	const handleHoverEnd = () => {
		setOver(false);
		window.restoreCursor();
	};

	return (
		<motion.li className='relative h-12 md:mt-4 xl:h-20 font-albra text-5xl 2xl:text-6xl text-white'>
			<c.Mobile>
				<motion.span className={`block w-full text-xl font-albra-bd flex flex-row justify-between`}>
					<Link to={url.replace('https://osprimeirosbrasileiros.mn.ufrj.br', '')}>{label}</Link>
					<img src={icAv} className='w-8 h-8 opacity-50' />
				</motion.span>
			</c.Mobile>
			<c.Tablet>
				<motion.span className={`block w-full text-4xl font-albra-bd flex flex-row justify-between`}>
					<Link to={url.replace('https://osprimeirosbrasileiros.mn.ufrj.br', '')}>{label}</Link>
					<img src={icAv} className='w-12 h-12 opacity-50' />
				</motion.span>
			</c.Tablet>
			<c.Desktop>
				<motion.span className={`absolute left-0 top-5 ${over ? `hidden` : `block`}`}>{label}</motion.span>
				<motion.span
					initial={{ opacity: 0 }}
					onHoverStart={handleHoverStart}
					onHoverEnd={handleHoverEnd}
					whileHover={{ opacity: 1, scale: 1.4, originX: 0 }}
					transition={{ opacity: { duration: 0 }, scale: { duration: 0.1 } }}
					className='block absolute w-full left-0 top-5 font-albra-bd'>
					<Link to={url.replace('https://osprimeirosbrasileiros.mn.ufrj.br', '')}>{label}</Link>
				</motion.span>
			</c.Desktop>
		</motion.li>
	);
};

const View = styled(motion.div)`
	position: absolute;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: var(--dark-grey);
`;
