import React from 'react';
import ReactDOM from 'react-dom';
import Application from './application';
import { BrowserRouter as Router } from 'react-router-dom';
import { theme, GlobalStyle, ThemeProvider } from './application/theme';
import * as serviceWorker from './serviceWorker';

import './index.css';

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<GlobalStyle />
			<Router basename={process.env.REACT_APP_PUBLIC_URL}>
				<Application />
			</Router>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
