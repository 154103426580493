import React, { useContext, useEffect, useState } from 'react';
import { motion, useTransform, useMotionValue } from 'framer-motion';
import styled from 'styled-components';

import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';

export default ({ slides = [] }) => {
	useEffect(() => {}, []);
	// console.log('---slides', slides);
	return (
		<View height={(slides.length + 1) * 100}>
			<Controller>
				<Scene duration={`${slides.length * 100}%`} triggerHook={`onLeave`} pin>
					<Timeline>
						{slides?.map((slide, index) => (
							<Slide key={index} index={index} {...slide} />
						))}
						{/* <EmptySlide /> */}
					</Timeline>
				</Scene>
			</Controller>
		</View>
	);
};

const Slide = ({ index, title, img, ariaDescription, imgAuthor, imgCopyright, imgCredit, backgroundColor }) => {
	const [loading, setLoading] = useState(true);
	const from = index === 0 ? { scale: 1 } : { scale: 1, opacity: 0 };
	const to = index === 0 ? { scale: 1.3 } : { scale: 1.3, opacity: 1 };

	const present = () => {
		setLoading(false);
	};

	return (
		<>
			<Tween from={from} to={to}>
				<SlideView style={{ backgroundColor }}>
					<motion.img
						src={img.mediaItemUrl}
						alt={ariaDescription}
						onLoad={present}
						initial={`hidden`}
						animate={!loading ? `visible` : `hidden`}
						variants={{ visible: { opacity: 1 }, hidden: { opacity: 0 } }}
					/>
				</SlideView>
			</Tween>
			<Tween from={{ opacity: 0 }} to={{ opacity: 1 }}>
				<div className='absolute w-screen h-screen left-0 top-0' aria-hidden>
					<div className='absolute left-0 bottom-0 w-screen h-72 bg-gradient-to-t from-black opacity-80' />
				</div>
				<figcaption className='absolute top-0 left-0 w-screen h-screen text-center font-mon flex flex-col p-10'>
					<div className='mt-auto' aria-hidden />
					{title && <span className='block text-xl text-white'>{title}</span>}
					{imgAuthor && <span className='block  text-xs text-white'>{imgAuthor}</span>}
					{imgCopyright && <span className='block  text-xs text-white'>{imgCopyright}</span>}
					{imgCredit && <span className='block  text-xs text-white italic'>{imgCredit}</span>}
				</figcaption>
			</Tween>
		</>
	);
};

const EmptySlide = ({ index }) => {
	return (
		<Tween from={{ opacity: 0 }} to={{ opacity: 1 }}>
			<SlideView index={index} />
		</Tween>
	);
};

const View = styled(motion.section)`
	position: relative;
	width: 100vw;
	overflow: hidden;
	height: ${(p) => p.height}vh;
`;

const SlideView = styled(motion.figure)`
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	width: 100vw;
	height: 100vh;
	// background: url(${(p) => p.image}) no-repeat center center;
	// background-size: cover;
	${(p) => (p.backgroundColor ? `background-color:${p.backgroundColor};` : ``)}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;
