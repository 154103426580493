import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import icNext from '../assets/icons/avancar.svg';
import icPrevious from '../assets/icons/voltar.svg';
import icLogos from '../assets/logos/logo-footer.svg';
import findIndex from 'lodash/findIndex';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import { useGlobalState } from '../state';

import * as c from './';

export default ({ url }) => {
	const [navigation_flat] = useGlobalState(`navigation_flat`);
	const [local, setLocal] = useState(null);
	const h = useHistory();
	const { pathname } = useLocation();

	useEffect(() => {
		if (url && navigation_flat) {
			const index = findIndex(navigation_flat, (o) => o.url === url || o.url === `${url}/`);

			let pindex = index - 1;
			const nindex = index + 1;

			if (pindex < 0) pindex = 0;

			const pitem = navigation_flat[pindex];
			const nitem = navigation_flat[nindex];

			const _local = {};

			if (pitem.parentId) {
				_local.previous = pitem.label;
				_local.previousUrl = pitem.url;
			}

			if (nitem.parentId) {
				_local.next = nitem.label;
				_local.nextUrl = nitem.url;
			}

			setLocal({ ..._local });
		}
	}, [navigation_flat, url]);

	const go = (url) => {
		h.push(url);
	};

	return (
		<>
			<c.Mobile>
				<footer className='w-screen'>
					<nav className='my-7'>
						{local?.previous && (
							<Link to={local?.previousUrl} className='block w-full flex items-center text-xs font-mon uppercase text-white pl-5'>
								<img src={icPrevious} className='w-8 h-8 mr-2' />

								{local?.previous}
							</Link>
						)}
						{local?.next && (
							<Link to={local?.nextUrl} className='block w-full flex justify-end items-center text-xs font-mon uppercase text-white pr-5'>
								{local?.next}
								<img src={icNext} className='w-8 h-8 ml-2' />
							</Link>
						)}
					</nav>
				</footer>
			</c.Mobile>
			<c.NotMobile>
				<footer className='w-screen h-32 p-10'>
					<nav>
						<ul className='flex flex-row justify-between'>
							{local?.previous ? (
								<motion.li whileHover={{ scale: 1.1 }} onHoverStart={() => window.changeCursor(`select`)} onHoverEnd={() => window.restoreCursor()}>
									<Link to={local?.previousUrl} className='flex justify-end items-center'>
										<img src={icPrevious} className='w-12 h-12 ' />
										<span className='block ml-4 font-mon text-base text-white uppercase'>{local?.previous}</span>
									</Link>
								</motion.li>
							) : (
								<div className='w-20' aria-hidden />
							)}

							{pathname.indexOf(`/mundo-indigena/`) > -1 && (
								<figure className='w-56'>
									{/* <img src={icLogos} alt='' className='' /> */}
									<img src={`${process.env.PUBLIC_URL}/assets/media/logos-redux.png`} />
								</figure>
							)}

							{local?.next ? (
								<motion.li whileHover={{ scale: 1.1 }} onHoverStart={() => window.changeCursor(`select`)} onHoverEnd={() => window.restoreCursor()}>
									<Link to={local?.nextUrl} className='flex justify-end items-center'>
										<span className='block font-mon text-base text-white uppercase'>{local?.next}</span>
										<img src={icNext} className='w-12 h-12 ml-4' />
									</Link>
								</motion.li>
							) : (
								<div className='w-20' aria-hidden />
							)}
						</ul>
					</nav>
				</footer>
			</c.NotMobile>
		</>
	);
};

const View = styled(motion.footer)`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100vw;
	height: 110px;
	// background-color: red;
	font-size: 100px;
	color: white;
`;

const Arrow = styled(motion.img)`
	display: block;
	width: 3.646vw;
	height: 3.646vw;
	// background-color: blue;
`;

const Label = styled(motion.span)`
	display: block;
	font-family: MontserratRegular;
	font-size: 1.302vw;
	text-transform: uppercase;
	color: white;
`;

const LogoWrapper = styled.div`
	width: 100%;
`;

const Logo = styled.img`
	display: block;
	flext: 0 0 26%;
	width: 26%;
	height: auto;
	margin: auto;
`;
