import DOMPurify from 'dompurify';

export const flatListToHierarchical = (data = [], { idKey = 'key', parentKey = 'parentId', childrenKey = 'children' } = {}) => {
	const tree = [];
	const childrenOf = {};
	data.forEach((item) => {
		const newItem = { ...item };
		const { [idKey]: id, [parentKey]: parentId = 0 } = newItem;
		childrenOf[id] = childrenOf[id] || [];
		newItem[childrenKey] = childrenOf[id];
		parentId ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem) : tree.push(newItem);
	});
	return tree;
};

const { sanitize } = DOMPurify;

export const prepare = (text) => {
	return sanitize(text).replace(/(\r\n)/g, `<br/>`);
};
