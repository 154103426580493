import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';

import * as c from '../components';

export default () => {
	const history = useHistory();
	return (
		<View>
			<c.Intro />
		</View>
	);
};

const View = styled(motion.div)`
	position: absolute;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: var(--dark-grey);
`;
