import { request, gql } from 'graphql-request';

export const QUERY_SINGLE_PAGE = gql`
	query QUERY_SINGLE_PAGE($uri: ID!) {
		page(id: $uri, idType: URI) {
			id
			tmpl_internas {
				blocks {
					type
					title
					subtitle
					obs
					text
					gallery {
						title
						ariaDescription
						backgroundColor
						imgAuthor
						imgCopyright
						imgCredits
						backgroundColor
						img {
							id
							mediaItemUrl
							mediaDetails {
								height
								width
							}
						}
						imgMobile {
							mediaItemUrl
							mediaDetails {
								height
								width
							}
						}
						imgThumb {
							mediaItemUrl
							mediaDetails {
								height
								width
							}
						}
					}
					externalVideo
					video {
						mediaItemUrl
					}
				}
			}
		}
	}
`;

export const QUERY_OS_POVOS = gql`
	query QUERY_OS_POVOS($uri: ID!) {
		page(id: $uri, idType: URI) {
			id
			title
			povos {
				list {
					label
					url {
						title
						target
						url
					}
					image {
						mediaItemUrl
					}
				}
			}
		}
	}
`;

export const QUERY_FICHA_TECNICA = gql`
	query QUERY_FICHA_TECNICA($uri: ID!) {
		page(id: $uri, idType: URI) {
			id
			title
			ficha_tecnica {
				block {
					title
					items {
						title
						content
					}
				}
			}
		}
	}
`;

export const QUERY_CONTEUDOS_EXTRAS = gql`
	query QUERY_CONTEUDOS_EXTRAS($uri: ID!) {
		page(id: $uri, idType: URI) {
			id
			title
			conteudos_extras {
				list {
					cover {
						mediaItemUrl
					}
					title
					file
					isExpo
				}
			}
		}
	}
`;

export const QUERY_MENU = gql`
	query QUERY_MENU($id: ID!) {
		menu(id: $id, idType: NAME) {
			id
			name
			slug
			count
			menuItems(first: 40) {
				nodes {
					id
					label
					url
					parentId
				}
			}
		}
	}
`;

export const fetcher = (query, variables) => {
	// return request(process.env.REACT_APP_API, query, variables);
	return request(`https://osprimeirosbrasileiros.mn.ufrj.br/graphql`, query, variables);
};
