import React from 'react';

export default ({ className }) => {
	return (
		<svg className={`${className}`} viewBox='0 0 361.875 55.898'>
			<defs>
				<filter id='a' x='172' y='17.449' width='79.875' height='21' filterUnits='userSpaceOnUse'>
					<feOffset dy='3' />
					<feGaussianBlur stdDeviation='3' result='blur' />
					<feFlood floodOpacity='.161' />
					<feComposite operator='in' in2='blur' />
					<feComposite in='SourceGraphic' />
				</filter>
				<filter id='b' x='282' y='17.449' width='79.875' height='21' filterUnits='userSpaceOnUse'>
					<feOffset dy='3' />
					<feGaussianBlur stdDeviation='3' result='blur-2' />
					<feFlood floodOpacity='.161' />
					<feComposite operator='in' in2='blur-2' />
					<feComposite in='SourceGraphic' />
				</filter>
				<filter id='c' x='169.879' y='0' width='39.07' height='55.898' filterUnits='userSpaceOnUse'>
					<feOffset dy='3' />
					<feGaussianBlur stdDeviation='3' result='blur-3' />
					<feFlood floodOpacity='.161' />
					<feComposite operator='in' in2='blur-3' />
					<feComposite in='SourceGraphic' />
				</filter>
				<filter id='d' x='190.705' y='0' width='39.07' height='55.898' filterUnits='userSpaceOnUse'>
					<feOffset dy='3' />
					<feGaussianBlur stdDeviation='3' result='blur-4' />
					<feFlood floodOpacity='.161' />
					<feComposite operator='in' in2='blur-4' />
					<feComposite in='SourceGraphic' />
				</filter>
				<filter id='e' x='110' y='17.449' width='79.875' height='21' filterUnits='userSpaceOnUse'>
					<feOffset dy='3' />
					<feGaussianBlur stdDeviation='3' result='blur-5' />
					<feFlood floodOpacity='.161' />
					<feComposite operator='in' in2='blur-5' />
					<feComposite in='SourceGraphic' />
				</filter>
				<filter id='f' x='0' y='17.449' width='79.875' height='21' filterUnits='userSpaceOnUse'>
					<feOffset dy='3' />
					<feGaussianBlur stdDeviation='3' result='blur-6' />
					<feFlood floodOpacity='.161' />
					<feComposite operator='in' in2='blur-6' />
					<feComposite in='SourceGraphic' />
				</filter>
				<filter id='g' x='152.926' y='0' width='39.07' height='55.898' filterUnits='userSpaceOnUse'>
					<feOffset dy='3' />
					<feGaussianBlur stdDeviation='3' result='blur-7' />
					<feFlood floodOpacity='.161' />
					<feComposite operator='in' in2='blur-7' />
					<feComposite in='SourceGraphic' />
				</filter>
				<filter id='h' x='132.099' y='0' width='39.07' height='55.898' filterUnits='userSpaceOnUse'>
					<feOffset dy='3' />
					<feGaussianBlur stdDeviation='3' result='blur-8' />
					<feFlood floodOpacity='.161' />
					<feComposite operator='in' in2='blur-8' />
					<feComposite in='SourceGraphic' />
				</filter>
			</defs>
			<g filter='url(#a)' transform='translate(0 -.004)'>
				<path data-name='Linha 2' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3' d='M242.87 24.95h-61.875' />
			</g>
			<g filter='url(#b)' transform='translate(0 -.004)'>
				<path data-name='Linha 23' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3' d='M352.87 24.95h-61.875' />
			</g>
			<g filter='url(#c)' transform='translate(0 -.004)'>
				<path data-name='Caminho 1' d='M198.89 42.842l-17.888-17.888L198.89 7.066' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3' />
			</g>
			<g filter='url(#d)' transform='translate(0 -.004)'>
				<path
					data-name='Caminho 2'
					d='M219.715 42.842l-17.888-17.888 17.888-17.888'
					fill='none'
					stroke='#fff'
					strokeMiterlimit='10'
					strokeWidth='3'
				/>
			</g>
			<g data-name='avancar'>
				<g filter='url(#e)' transform='translate(.005 .003)'>
					<path data-name='Linha 2' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3' d='M119 24.95h61.875' />
				</g>
				<g filter='url(#f)' transform='translate(.005 .003)'>
					<path data-name='Linha 23' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3' d='M9 24.95h61.875' />
				</g>
				<g filter='url(#g)' transform='translate(.005 .003)'>
					<path data-name='Caminho 1' d='M162.99 7.058l17.888 17.888-17.888 17.888' fill='none' stroke='#fff' strokeMiterlimit='10' strokeWidth='3' />
				</g>
				<g filter='url(#h)' transform='translate(.005 .003)'>
					<path
						data-name='Caminho 2'
						d='M142.155 7.058l17.888 17.888-17.888 17.888'
						fill='none'
						stroke='#fff'
						strokeMiterlimit='10'
						strokeWidth='3'
					/>
				</g>
			</g>
		</svg>
	);
};
