import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion, animate, useMotionValue } from 'framer-motion';
import icNext from '../assets/icons/avancar.svg';
import icPrevious from '../assets/icons/voltar.svg';

import * as c from '../components';

export default ({ data = [], horizontal, id, onTap }) => {
	const slidesRef = useRef(null);
	const [index, setIndex] = useState(0);
	const x = useMotionValue(0);

	const _onTap = () => {
		onTap(id, index, data);
	};

	const handlePrevTap = () => {
		let newIndex = index - 1;

		if (newIndex < 0) {
			newIndex = 0;
		}

		let offset = 0;

		slidesRef.current.map((slide, idx) => {
			if (idx < newIndex) {
				offset += slide.width;
			} else if (idx === newIndex) {
				offset += slide.width / 2;
			}
		});

		animate(x, -offset, { transition: { duration: 0.7, ease: [0.53, 0.02, 0.49, 1] } });

		setIndex(newIndex);
	};

	const handleNextTap = () => {
		let newIndex = index + 1;

		if (newIndex > data.length - 1) {
			newIndex = data.length - 1;
		}

		let offset = 0;

		slidesRef.current.map((slide, idx) => {
			if (idx < newIndex) {
				offset += slide.width;
			} else if (idx === newIndex) {
				offset += slide.width / 2;
			}
		});

		animate(x, -offset, { transition: { duration: 0.7, ease: [0.53, 0.02, 0.49, 1] } });

		setIndex(newIndex);
	};

	const handleSlideLoad = (e, index) => {
		if (!slidesRef.current) {
			slidesRef.current = new Array(data.length);
		}
		slidesRef.current[index] = e.target.getBoundingClientRect();

		let allLoaded = 0;

		slidesRef.current.map((slide) => {
			if (slide) {
				allLoaded += 1;
			}
		});

		if (allLoaded === data.length) {
			const offset = slidesRef.current[0].width / 2;
			animate(x, -offset, { transition: { duration: 0.7, ease: [0.53, 0.02, 0.49, 1] } });
		}
	};

	return (
		<section className='relative w-screen h-auto' style={{ height: `26vw` }}>
			<div className='grid place-items-center'>
				<c.Separator className='w-1/3 h-20' />
			</div>
			<motion.figure className='absolute w-screen h-full overflow-hidden flex flex-row mt-20' aria-role='group'>
				<motion.div style={{ x }} className='w-screen h-full flex flex-row'>
					<div className='relative left-0 h-full flex-shrink-0 flex-grow-0' style={{ width: `50vw` }} />
					{data?.map((item, idx) => (
						<motion.figure
							key={item.id}
							onTap={_onTap}
							onHoverStart={() => window.changeCursor(`mais`)}
							onHoverEnd={() => window.restoreCursor()}
							className={`h-full flex-shrink-0 flex-grow-0 overflow-hidden ${index === idx ? `` : `opacity-30`}`}>
							<LzImg
								src={item.imgThumb.mediaItemUrl}
								alt={item.ariaDescription}
								index={idx}
								className='h-full object-scale-down p-2'
								onLoad={handleSlideLoad}
								whileHover={
									index === idx
										? {
												scale: 1.1,
												zIndex: 1,
												transition: { stiffness: 1000, dumping: 100 },
										  }
										: null
								}
							/>
						</motion.figure>
					))}
					<div className='relative left-0 h-full flex-shrink-0 flex-grow-0 ml-4' style={{ width: `30vw` }} />
				</motion.div>
				<Gradient className='absolute w-screen h-full pointer-events-none' aria-hidden />
				<div className='absolute left-0 top-1/2 -mt-12 w-screen h-24 flex items-center justify-between pointer-events-none px-10 z-10'>
					<motion.img onTap={handlePrevTap} src={icPrevious} alt='' className='w-24 h-24 pointer-events-auto' />
					<motion.img onTap={handleNextTap} src={icNext} alt='' className='w-24 h-24 pointer-events-auto' />
				</div>
			</motion.figure>
		</section>
	);
};

const LzImg = ({ src, alt, className, onLoad, index, ...rest }) => {
	const [loading, setLoading] = useState(true);
	return (
		<motion.img
			{...rest}
			src={src}
			onLoad={(e) => {
				setLoading(false);
				onLoad(e, index);
			}}
			initial={`hidden`}
			animate={!loading ? `visible` : `hidden`}
			alt={alt}
			className={`${className}`}
			variants={{ visible: { opacity: 1 }, hidden: { opacity: 0 } }}
		/>
	);
};

const Gradient = styled.div`
	height: 26vw;
	background-image: ${(props) => props.theme.colors.grayDarkGradientHorizontal};
`;
