import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Loader from '../assets/icons/loader.gif';

//avaliar a questão da animação
export default () => {
	useEffect(() => {
		setTimeout(() => window.scrollTo(0, 0), 700);
	}, []);
	return (
		<View {...a} aria-hidden>
			<img src={Loader} />
		</View>
	);
};

const View = styled(motion.div)`
	position: fixed;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	background-color: ${(p) => p.theme.colors.grayDark};
	z-index: 20;

	img {
		display: block;
		width: 100px;
		height: 100px;
	}
`;

const a = {
	initial: { opacity: 0 },
	animate: { opacity: 1, transition: { duration: 0.5 } },
	exit: { opacity: 0 },
};
