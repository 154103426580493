import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import ReactAudioPlayer from 'react-audio-player';
import { ReactComponent as SoundOff } from '../assets/icons/sound-off.svg';
import { ReactComponent as SoundOn } from '../assets/icons/sound-on.svg';

const _sounds = {
	'/o-encontro/a-viagem': '1_a_viagem.mp3',
	'/o-encontro/os-primeiros-brasileiros': '2_mar_de_indios_a.mp3',
	'/o-encontro/o-imaginario-colonial': '3_imaginario_colonial.mp3',
	'/o-encontro/a-conquista-tem-genero': '3_imaginario_colonial.mp3',
	'/mundo-colonial/armadilha-da-colonizacao': '4_a_armadilha_colonizacao.mp3',
	'/mundo-colonial/o-abrigo-missionario': '5_abrigo_missionario.mp3',
	'/mundo-colonial/o-indianismo-e-o-imperio-do-brasil': '7_representacao_romantica.mp3',
	'/mundo-colonial/extincao-dos-aldeamentos': '6_extincao_aldeamentos.mp3',
};
// eckhout.mp3
export default () => {
	const { pathname } = useLocation();
	const [on, setOn] = useState(false);
	const [audio, setAudio] = useState(null);
	const handleTap = () => setOn(!on);

	useEffect(() => {
		let found = false;

		Object.keys(_sounds).map((key) => {
			if (pathname.indexOf(key) > -1) {
				found = key;
			}
		});

		let sound = `eckhout.mp3`;

		if (found) {
			sound = _sounds[found];
		}

		setAudio(sound);
	}, [pathname]);

	return (
		<motion.div className='fixed w-10 h-10 grid place-items-center md:w-10 md:h-10 top-2 right-7 md:top-4 md:right-7 z-50' onTap={handleTap}>
			{on ? (
				<>
					<ReactAudioPlayer src={`${process.env.PUBLIC_URL}/assets/audios/${audio}`} autoPlay loop />
					<SoundOn />
				</>
			) : (
				<SoundOff />
			)}
		</motion.div>
	);
};
