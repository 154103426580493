import React, { useContext, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { motion, useMotionValue, useTransform, animate } from 'framer-motion';
import { ScrollContext } from '../context';
import { prepare } from '../utils';
import isEmpty from 'lodash/isEmpty';

export default ({ title, leading, subtitle, article, caption, type }) => {
	const value = useContext(ScrollContext);
	const motionValue = useMotionValue(0);
	const position = useTransform(motionValue, [0, 1], [0, -100]);

	useEffect(() => {
		motionValue.set(value);
		animate(motionValue, value, { duration: 1, ease: `anticipate` });
	}, [value]);

	return (
		<>
			{(type === `1A` || type === `1B` || type === `1C`) && (
				<motion.section className='relative w-1/3 pr-10 ml-72 mt-24  text-white z-0' style={{ y: position }}>
					{title && <h2 className='font-albra-bd text-5xl xl:text-6xl 2xl:text-8xl' dangerouslySetInnerHTML={{ __html: prepare(title) }} />}
					{subtitle && <h3 className='font-mon-bd text-3xl mt-4 2xl:text-5xl' dangerouslySetInnerHTML={{ __html: prepare(subtitle) }} />}
					{leading && <p className='font-mon text-xl mt-4 2xl:text-2xl' dangerouslySetInnerHTML={{ __html: prepare(leading) }} />}
					{article && <p className='font-mon text-sm mt-4 2xl:text-xl' dangerouslySetInnerHTML={{ __html: prepare(article) }} />}
					{caption && <p className='font-mon text-xs mt-4 2xl:text-base' dangerouslySetInnerHTML={{ __html: prepare(caption) }} />}
				</motion.section>
			)}
			{type === `2A` && (
				<motion.section className='relative w-1/2 ml-72 mt-96  2xl:p-56 2xl:w-2/3 text-white' style={{ y: position }}>
					{title && <h2 className='font-albra text-7xl 2xl:text-8xl' dangerouslySetInnerHTML={{ __html: prepare(title) }} />}
					{subtitle && <h3 className='font-mon text-3xl mt-4 2xl:text-4xl' dangerouslySetInnerHTML={{ __html: prepare(subtitle) }} />}
					{leading && <p className='font-mon text-2xl mt-4' dangerouslySetInnerHTML={{ __html: prepare(leading) }} />}
					{article && <p className='font-mon text-sm mt-4 2xl:text-xl text-gray-100' dangerouslySetInnerHTML={{ __html: prepare(article) }} />}
					{caption && <p className='font-mon text-xs mt-4 2xl:text-sm' dangerouslySetInnerHTML={{ __html: prepare(caption) }} />}
				</motion.section>
			)}
			{type === `2A-2` && (
				<motion.section className='relative w-1/2 ml-72 mt-96 pt-40 md:pt-10 text-white 2xl:p-56 2xl:pb-0 2xl:w-2/3' style={{ y: position }}>
					{title && <h2 className='font-albra text-7xl 2xl:text-8xl' dangerouslySetInnerHTML={{ __html: prepare(title) }} />}
					{subtitle && <h3 className={`font-mon text-3xl mt-4`} dangerouslySetInnerHTML={{ __html: prepare(subtitle) }} />}
					{leading && (
						<p className={`font-mon text-2xl mt-4 ${isEmpty(title) ? `pt-32` : `mt-4`}`} dangerouslySetInnerHTML={{ __html: prepare(leading) }} />
					)}
					{article && <p className='font-mon text-sm mt-4 text-gray-100  2xl:text-base' dangerouslySetInnerHTML={{ __html: prepare(article) }} />}
					{caption && <p className='font-mon text-xs mt-4  2xl:text-sm' dangerouslySetInnerHTML={{ __html: prepare(caption) }} />}
				</motion.section>
			)}
			{type === `3A` && (
				<motion.section className='relative w-1/2 ml-72 mt-96 2xl:pt-56 text-white' style={{ y: position }}>
					{title && <h2 className='font-albra-bd text-7xl 2xl:text-8xl ' dangerouslySetInnerHTML={{ __html: prepare(title) }} />}
					{subtitle && <p className='font-mon text-xl 2xl:text-3xl' dangerouslySetInnerHTML={{ __html: prepare(subtitle) }} />}
					{leading && <p className='font-mon text-2xl mt-4 2xl:text-3xl' dangerouslySetInnerHTML={{ __html: prepare(leading) }} />}
					{article && <p className='font-mon text-sm mt-4 text-gray-100 2xl:text-xl' dangerouslySetInnerHTML={{ __html: prepare(article) }} />}
					{caption && <p className='font-mon text-xs mt-4 2xl:text-base' dangerouslySetInnerHTML={{ __html: prepare(caption) }} />}
				</motion.section>
			)}
			{type === `3A-2` && (
				<motion.section className='relative w-1/3 ml-72 mt-96 pt-10 md:w-5/12 md:mt-96 2xl:pt-60 2xl:w-5/12 text-white' style={{ y: position }}>
					{title && <h2 className='font-albra-bd text-6xl 2xl:text-7xl ' dangerouslySetInnerHTML={{ __html: prepare(title) }} />}
					{subtitle && <p className='font-mon text-xl 2xl:text-2xl' dangerouslySetInnerHTML={{ __html: prepare(subtitle) }} />}
					{leading && <p className='font-mon text-2xl mt-4' dangerouslySetInnerHTML={{ __html: prepare(leading) }} />}
					{article && <p className='font-mon text-sm mt-4 2xl:text-xl  text-gray-100' dangerouslySetInnerHTML={{ __html: prepare(article) }} />}
					{caption && <p className='font-mon text-xs mt-4 2xl:text-sm' dangerouslySetInnerHTML={{ __html: prepare(caption) }} />}
				</motion.section>
			)}
			{type === `3D` && (
				<motion.section className='relative w-1/2 mt-96 pt-40 text-white text-center mx-auto 2xl:w-6/12 2xl:pt-72' style={{ y: position }}>
					{title && <h2 className='font-albra-md text-5xl uppercase' dangerouslySetInnerHTML={{ __html: prepare(title) }} />}
					{subtitle && <p className='font-mon text-xl 2xl:text-2xl uppercase' dangerouslySetInnerHTML={{ __html: prepare(subtitle) }} />}
					{leading && <p className='font-mon-bd text-sm  2xl:text-base mt-4' dangerouslySetInnerHTML={{ __html: prepare(leading) }} />}
					{article && (
						<p
							className={`font-mon text-xl mt-10 2xl:text-2xl text-gray-100 ${!leading ? `pt-12` : ``}`}
							dangerouslySetInnerHTML={{ __html: prepare(article) }}
						/>
					)}
					{caption && <p className='font-mon text-xs mt-4' dangerouslySetInnerHTML={{ __html: prepare(caption) }} />}
				</motion.section>
			)}
			{(type === `4A` || type === `4B` || type === `4C`) && (
				<motion.section className='relative w-1/2 ml-72 mt-10 2xl:mx-auto text-white' style={{ y: position }}>
					{title && <h2 className='font-albra-bd text-7xl 2xl:text-8xl' dangerouslySetInnerHTML={{ __html: prepare(title) }} />}
					{subtitle && <p className='font-mon text-xl  2xl:text-2xl' dangerouslySetInnerHTML={{ __html: prepare(subtitle) }} />}
					{leading && <p className='font-mon text-2xl mt-4  2xl:text-3xl' dangerouslySetInnerHTML={{ __html: prepare(leading) }} />}
					{article && <p className='font-mon text-sm mt-4  2xl:text-xl text-gray-100' dangerouslySetInnerHTML={{ __html: prepare(article) }} />}
					{caption && <p className='font-mon text-xs mt-4  2xl:text-base' dangerouslySetInnerHTML={{ __html: prepare(caption) }} />}
				</motion.section>
			)}
			{type === `4D` && (
				<motion.section className='relative w-1/2 ml-72 mt-10 xl:mx-auto text-white text-center' style={{ y: position }}>
					{title && <h2 className='font-albra-md text-7xl 2xl:text-8xl' dangerouslySetInnerHTML={{ __html: prepare(title) }} />}
					{subtitle && <p className='font-mon text-xl 2xl:text-2xl' dangerouslySetInnerHTML={{ __html: prepare(subtitle) }} />}
					{leading && <p className='font-mon-bd text-sm mt-4 2xl:text-lg' dangerouslySetInnerHTML={{ __html: prepare(leading) }} />}
					{article && <p className='font-mon text-xl mt-10 text-gray-100 2xl:text-2xl' dangerouslySetInnerHTML={{ __html: prepare(article) }} />}
					{caption && <p className='font-mon text-xs mt-4 2xl:text-base' dangerouslySetInnerHTML={{ __html: prepare(caption) }} />}
				</motion.section>
			)}
			{type === `7A` && (
				<motion.section className='relative w-full px-56 mt-96 2xl:w-7/12 2xl:px-0 2xl:mx-auto text-white text-center z-10' style={{ y: position }}>
					{title && <h2 className='font-mon text-2xl uppercase xl:text-3xl' dangerouslySetInnerHTML={{ __html: prepare(title) }} />}
					{subtitle && <p className='font-mon text-2xl uppercase xl:text-3xl' dangerouslySetInnerHTML={{ __html: prepare(subtitle) }} />}
					{leading && <p className='font-mon text-base mt-4  xl:text-xl' dangerouslySetInnerHTML={{ __html: prepare(leading) }} />}
					{article && (
						<p
							className={`font-mon text-xs mt-4 2xl:text-base text-gray-100 ${!leading ? `pt-12` : ``}`}
							dangerouslySetInnerHTML={{ __html: prepare(article) }}
						/>
					)}
					{caption && <p className='font-mon-bd text-xs mt-4' dangerouslySetInnerHTML={{ __html: prepare(caption) }} />}
				</motion.section>
			)}

			{type === `7B` && (
				<motion.section className='relative w-full px-48 mt-96 2xl:w-7/12 2xl:px-0 2xl:mx-auto  text-white text-center z-10' style={{ y: position }}>
					{title && <h2 className='font-mon text-2xl uppercase' dangerouslySetInnerHTML={{ __html: prepare(title) }} />}
					{subtitle && <p className='font-mon text-2xl uppercase' dangerouslySetInnerHTML={{ __html: prepare(subtitle) }} />}
					{leading && <p className='font-mon text-base xl:text-xl mt-4' dangerouslySetInnerHTML={{ __html: prepare(leading) }} />}
					{article && (
						<p
							className={`font-mon text-xs xl:text-base mt-4 text-gray-100 ${!leading ? `pt-12` : ``}`}
							dangerouslySetInnerHTML={{ __html: prepare(article) }}
						/>
					)}
					{caption && <p className='font-mon-bd text-xs mt-4' dangerouslySetInnerHTML={{ __html: prepare(caption) }} />}
				</motion.section>
			)}
		</>
	);
};
