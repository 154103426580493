/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { animationControls, motion } from 'framer-motion';
import { gsap, ScrollTrigger, TimelineLite } from 'gsap/all';
import icEntrar from '../assets/icons/entrar.svg';

const p = `${process.env.PUBLIC_URL}/assets/media`;

gsap.registerPlugin(ScrollTrigger);

const _slide = [
	{ title: 'os primeiros', subtitle: 'Brasileiros', text: `pt/o-encontro/a-viagem` },
	{
		title: 'O Encontro',
		text: `Nos primeiros contatos, a população autóctone não foi de forma alguma caracterizada pelos europeus como decadente, feia ou miserável. Era vista, ao contrário, com admiração e espanto.`,
	},
	{
		subtitle: 'o mundo',
		title: 'Colonial',
		text: `À medida que Portugal reforçou o seu controle sobre a região e o comércio tornou-se mais intenso e regular, as relações deixaram de ser eventuais e simétricas, para começar a gerar vínculos de dependência e escravizamento dos indígenas.`,
		url: '/pt/mundo-colonial/armadilha-da-colonizacao',
	},
	{
		subtitle: 'o mundo',
		title: 'Indígena',
		text: `As identidades, muitas vezes marcadas por meio das diferenças culturais, não são igualmente estáticas. Elas são utilizadas situacionalmente e podem se combinar de diferentes modos. As identidades não se excluem.`,
		url: '/pt/mundo-indigena/armadilhas',
	},
	{
		subtitle: 'o mundo',
		title: 'Contemporâneo',
		text: `Cultura não pode ser entendida como um elemento parado no tempo. Ela é modificada e transformada por meio das ações dos sujeitos localizados em situações históricas específicas, de acordo com as condições possíveis de seu exercício.`,
		url: '/pt/mundo-contemporaneo/os-povos',
	},
];

const _slide_en = [
	{ title: 'the first Peoples of', subtitle: 'Brazil', text: `` },
	{
		title: 'The Encounter',
		text: `In the first contacts, the autochthonous population was by no means characterised by Europeans as decadent, ugly or miserable. On the contrary, they were viewed with admiration and astonishment.`,
		url: '/en/the-encounter/the-journey',
	},
	{
		subtitle: 'The Colonial',
		title: 'World',
		text: `As Portugal strengthened its control over the region and trade became more regular and intensive, relations ceased to be occasional and symmetrical, and bonds of dependency and enslavement of the Indians began to be generated.`,
		url: '/en/the-colonial-world/the-iron-fist-of-colonisation',
	},
	{
		subtitle: 'The Indigenous',
		title: 'World',
		text: `Identities, often marked by cultural differences, are not equally static. They are used situationally and can be combined in different ways. Identities do not exclude each other.  `,
		url: '/en/the-indigenous-world/traps',
	},
	{
		subtitle: 'Contemporary',
		title: 'World',
		text: `Culture cannot be understood as an element that is frozen in time. It is modified and transformed through the actions of subjects located in specific historical situations, according to the possible conditions of its practice.`,
		url: '/en/contemporary-world/indigenous-groups',
	},
];
const _slide_es = [
	{ title: 'Los Primeros', subtitle: 'Brasileños', text: `` },
	{
		title: 'El Encuentro',
		text: `En los primeros contactos, la población indígena no fue, de ninguna manera, caracterizada por los europeos como decadente, fea o miserable. Por el contrario, fueron vistos con admiración y estupor.`,
		url: '/es/el-encuentro/el-viaje',
	},
	{
		subtitle: 'El Mundo',
		title: 'Colonial',
		text: `A medida que Portugal fortaleció su control sobre la región y el comercio se volvió más intenso y regular, las relaciones dejaron de ser ocasionales y simétricas para comenzar a generar lazos de dependencia y esclavización de los indígenas.`,
		url: '/es/el-mundo-colonial/la-mano-de-hierro-de-la-colonizacion',
	},
	{
		subtitle: 'El Mundo',
		title: 'Indígena',
		text: `Las identidades, muchas veces marcadas por medio de las diferencias culturales, no son igualmente estáticas. Ellas son utilizadas situacionalmente y pueden combinarse de modos diferentes. Las identidades no se excluyen.`,
		url: '/es/el-mundo-indigena/trampas',
	},
	{
		subtitle: 'Mundo',
		title: 'Contemporáneo',
		text: `La cultura no puede ser entendida como un elemento parado en el tiempo. Ella es modificada y transformada por medio de las acciones de los sujetos localizados en situaciones históricas específicas, de acuerdo con las condiciones posibles de su ejercicio.`,
		url: '/es/mundo-comtemporaneo-2/grupos-indigenas',
	},
];

export default () => {
	const [slide, setSlide] = useState(_slide);
	const h = useHistory();
	const { pathname } = useLocation();
	const anMobile = () => {
		gsap.set('.sl2,.sl3,.sl4,.sl5', { display: `none` });

		return (
			gsap
				.timeline({ defaults: { duration: 1 } })
				.fromTo('.sl1 .dm', { opacity: 0 }, { opacity: 0.4 })
				.fromTo('.sl1 .title', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` })
				.fromTo('.sl1 .subtitle', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` })
				.fromTo('.sl1 .ly1 img', { objectPosition: `70% 50%` }, { objectPosition: `50% 50%`, duration: 2 }, 0)
				.fromTo('.sl1 .ly2 img', { objectPosition: `40% 50%` }, { objectPosition: `70% 50%`, duration: 2 }, 0)
				.fromTo('.sl1 .ic', { opacity: 0 }, { opacity: 1 }, 2.5)

				// slide 2
				.fromTo('.sl2 .ly1', { opacity: 0, scale: 1 }, { opacity: 3, duration: 2, scale: `+=.2` }, `+=1`)
				.to('.sl2', { display: `flex` }, '<')

				.fromTo('.sl2 .ly1 img', { objectPosition: `40% 50%` }, { objectPosition: `60% 50%`, duration: 5 }, `<`)
				.fromTo('.sl2 .ly2 img', { opacity: 0 }, { opacity: 1, duration: 0.3 }, `<`)
				.fromTo('.sl2 .ly2 img', { objectPosition: `0% 50%` }, { objectPosition: `40% 50%`, duration: 3 }, `<`)
				.fromTo('.sl2 .dm', { opacity: 0 }, { opacity: 0.4 }, '<')
				.fromTo('.sl2 .title', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` }, '-=2.7')
				.fromTo('.sl2 .text', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` }, '-=2')
				.fromTo('.sl2 .ic', { opacity: 0 }, { opacity: 1 }, '-=1.8')
				.fromTo('.sl2 .icl', { opacity: 0 }, { opacity: 1 }, '-=1.8')

				// slide 3
				.fromTo('.sl3 .ly1', { opacity: 0, scale: 1 }, { opacity: 3, duration: 2, scale: 1 }, `+=1`)
				.to('.sl3', { display: `flex` }, '<')

				.fromTo('.sl3 .ly1 img', { objectPosition: `40% 40%` }, { objectPosition: `60% 40%`, duration: 12 }, `<`)
				.fromTo('.sl3 .ly2 img', { opacity: 0 }, { opacity: 1, duration: 0.3 }, `<`)
				.fromTo('.sl3 .ly2 img', { objectPosition: `0% 50%` }, { objectPosition: `90% 50%`, duration: 8 }, `<`)
				.fromTo('.sl3 .dm', { opacity: 0 }, { opacity: 0.4 }, '<')
				.fromTo('.sl3 .subtitle', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` }, '-=2.7')
				.fromTo('.sl3 .title', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` }, '-=.7')
				.fromTo('.sl3 .text', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` }, '-=.7')
				.fromTo('.sl3 .ic', { opacity: 0 }, { opacity: 1 }, '-=.6')
				.fromTo('.sl3 .icl', { opacity: 0 }, { opacity: 1 }, '-=.6')

				// slide 4
				.fromTo('.sl4 .ly1', { opacity: 0, scale: 1 }, { opacity: 3, duration: 2, scale: 1 }, `+=1`)
				.to('.sl4', { display: `flex` }, '<')

				.fromTo('.sl4 .ly1 img', { objectPosition: `0% 50%` }, { objectPosition: `60% 50%`, duration: 5 }, `<`)
				.fromTo('.sl4 .ly2 img', { opacity: 0 }, { opacity: 1, duration: 0.3 }, `<`)
				.fromTo('.sl4 .ly2 img', { objectPosition: `0% 50%` }, { objectPosition: `142% 50%`, duration: 3 }, `<`)
				.fromTo('.sl4 .dm', { opacity: 0 }, { opacity: 0.4 }, '<')
				.fromTo('.sl4 .subtitle', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` }, '-=4')
				.fromTo('.sl4 .title', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50`, delay: 0.4 }, '<')
				.fromTo('.sl4 .text', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50`, delay: 0.8 }, '<')
				.fromTo('.sl4 .ic', { opacity: 0 }, { opacity: 1 }, '<')
				.fromTo('.sl4 .icl', { opacity: 0 }, { opacity: 1 }, '<')

				// slide 5
				.fromTo('.sl5 .ly1', { opacity: 0, scale: 1 }, { opacity: 3, duration: 0.3, scale: 1 }, `+=1`)
				.to('.sl5', { display: `flex` }, '<')

				.fromTo('.sl5 .ly1 img', { objectPosition: `70% 50%` }, { objectPosition: `30% 50%`, duration: 5 }, `<`)
				.fromTo('.sl5 .ly2 img', { opacity: 0 }, { opacity: 1, duration: 0.3 }, `<`)
				.fromTo('.sl5 .ly2 img', { objectPosition: `90% 50%` }, { objectPosition: `30% 50%`, duration: 6 }, `<`)
				.fromTo('.sl5 .ly3 img', { opacity: 0 }, { opacity: 1, duration: 0.3 }, `<`)
				.fromTo('.sl5 .ly3 img', { objectPosition: `90% 50%` }, { objectPosition: `30% 50%`, duration: 7 }, `<`)
				.fromTo('.sl5 .dm', { opacity: 0 }, { opacity: 0.4 }, '<')
				.fromTo('.sl5 .subtitle', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` }, '-=4')
				.fromTo('.sl5 .title', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50`, delay: 0.4 }, '<')
				.fromTo('.sl5 .text', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50`, delay: 0.8 }, '<')
				.fromTo('.sl5 .ic', { opacity: 0 }, { opacity: 1 }, '<')
				.fromTo('.sl5 .icl', { opacity: 0 }, { opacity: 1 }, '<')
		);
	};

	const anTablet = () => {
		gsap.set('.sl2,.sl3,.sl4,.sl5', { display: `none` });
		return (
			gsap
				.timeline({ defaults: { duration: 1 } })
				.fromTo('.sl1 .dm', { opacity: 0 }, { opacity: 0.4 })
				.fromTo(
					'.sl1 .title',
					{ opacity: 0, y: `+=50`, scale: `+=.5`, x: `+=300` },
					{ opacity: 1, y: `-=50`, scale: `-=.5`, x: `-=300` }
				)
				.fromTo('.sl1 .subtitle', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` })
				.fromTo('.sl1 .ly1 img', { objectPosition: `0% 50%` }, { objectPosition: `100% 50%`, duration: 2 }, 0)
				.fromTo('.sl1 .ly2 img', { objectPosition: `150% 50%` }, { objectPosition: `0% 50%`, duration: 2 }, 0)
				.fromTo('.sl1 .ic', { opacity: 0 }, { opacity: 1, duration: 1 }, 2.5)

				// slide 2
				.fromTo('.sl2 .ly1', { opacity: 0, scale: 1 }, { opacity: 3, duration: 2, scale: `+=.2` }, `+=1`)
				.to('.sl2', { display: `flex` }, '<')
				.fromTo('.sl2 .ly1 img', { objectPosition: `40% 50%` }, { objectPosition: `60% 50%`, duration: 5 }, `<`)
				.fromTo('.sl2 .ly2 img', { opacity: 0, y: '-=160px', scale: '+=.2' }, { opacity: 1, duration: 0.3 }, `<`)
				.fromTo('.sl2 .ly2 img', { objectPosition: `100% 0%` }, { objectPosition: `0% 0%`, duration: 3 }, `<`)
				.fromTo('.sl2 .dm', { opacity: 0 }, { opacity: 0.4 }, '<')
				.fromTo('.sl2 .title', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` }, '-=2.7')
				.fromTo('.sl2 .text', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` }, '-=2')
				.fromTo('.sl2 .ic', { opacity: 0 }, { opacity: 1, duration: 1 }, '-=1.8')
				.fromTo('.sl2 .icl', { opacity: 0 }, { opacity: 1, duration: 1 }, '-=1.8')

				// slide 3
				.fromTo('.sl3 .ly1', { opacity: 0, scale: 1 }, { opacity: 3, duration: 2, scale: 1 }, `+=1`)
				.to('.sl3', { display: `flex` }, '<')

				.fromTo('.sl3 .ly1 img', { objectPosition: `40% 40%` }, { objectPosition: `60% 40%`, duration: 12 }, `<`)
				.fromTo('.sl3 .ly2 img', { opacity: 0 }, { opacity: 1, duration: 0.3 }, `<`)
				.fromTo('.sl3 .ly2 img', { objectPosition: `0% 50%` }, { objectPosition: `100% 50%`, duration: 8 }, `<`)
				.fromTo('.sl3 .dm', { opacity: 0 }, { opacity: 0.4 }, '<')
				.fromTo('.sl3 .subtitle', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` }, '-=2.7')
				.fromTo('.sl3 .title', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` }, '-=.7')
				.fromTo('.sl3 .text', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` }, '-=.7')
				.fromTo('.sl3 .ic', { opacity: 0 }, { opacity: 1, duration: 1 }, '-=.7')
				.fromTo('.sl3 .icl', { opacity: 0 }, { opacity: 1, duration: 1 }, '-=.7')

				// slide 4
				.fromTo('.sl4 .ly1', { opacity: 0, scale: 1 }, { opacity: 3, duration: 2, scale: 1 }, `+=1`)
				.to('.sl4', { display: `flex` }, '<')
				.fromTo('.sl4 .ly1 img', { objectPosition: `0% 50%` }, { objectPosition: `60% 50%`, duration: 3 }, `<`)
				.fromTo('.sl4 .ly2 img', { opacity: 0 }, { opacity: 1, duration: 0.3 }, `<`)
				.fromTo('.sl4 .ly2 img', { objectPosition: `0% 50%` }, { objectPosition: `25% 50%`, duration: 4 }, `<`)
				.fromTo('.sl4 .dm', { opacity: 0 }, { opacity: 0.4 }, '<')
				.fromTo('.sl4 .subtitle', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` }, '-=4')
				.fromTo('.sl4 .title', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50`, delay: 0.4 }, '<')
				.fromTo('.sl4 .text', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50`, delay: 0.8 }, '<')
				.fromTo('.sl4 .ic', { opacity: 0 }, { opacity: 1, duration: 1 }, '<')
				.fromTo('.sl4 .icl', { opacity: 0 }, { opacity: 1, duration: 1 }, '<')

				// slide 5
				.fromTo('.sl5 .ly1', { opacity: 0, scale: 1 }, { opacity: 3, duration: 0.3, scale: 1 }, `+=1`)
				.to('.sl5', { display: `flex` }, '<')
				.fromTo('.sl5 .ly1 img', { objectPosition: `70% 50%` }, { objectPosition: `30% 50%`, duration: 5 }, `<`)
				.fromTo('.sl5 .ly2 img', { opacity: 0 }, { opacity: 1, duration: 0.3 }, `<`)
				.fromTo('.sl5 .ly2 img', { objectPosition: `90% 50%` }, { objectPosition: `30% 50%`, duration: 5 }, `<`)
				.fromTo('.sl5 .ly3 img', { opacity: 0 }, { opacity: 1, duration: 0.5 }, `<`)
				.fromTo('.sl5 .ly3 img', { objectPosition: `100% 50%` }, { objectPosition: `0% 50%`, duration: 5 }, `<`)
				.fromTo('.sl5 .dm', { opacity: 0 }, { opacity: 0.4 }, '<')
				.fromTo('.sl5 .subtitle', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` }, '-=3.8')
				.fromTo('.sl5 .title', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50`, delay: 0.4 }, '-=3.6')
				.fromTo('.sl5 .text', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50`, delay: 0.8 }, '-=3.4')
				.fromTo('.sl5 .ic', { opacity: 0 }, { opacity: 1, duration: 1 }, '-=3.2')
				.fromTo('.sl5 .icl', { opacity: 0 }, { opacity: 1, duration: 1 }, '-=3.2')
		);
	};

	const anDektop = () => {
		gsap.set('.sl2,.sl3,.sl4,.sl5', { display: `none` });
		return (
			gsap
				.timeline({ defaults: { duration: 1 } })
				.fromTo('.sl1 .dm', { opacity: 0 }, { opacity: 0.4 })
				.fromTo(
					'.sl1 .title',
					{ opacity: 0, y: `+=50`, scale: `+=.5`, x: `+=300` },
					{ opacity: 1, y: `-=50`, scale: `-=.5`, x: `-=300` }
				)
				.fromTo('.sl1 .subtitle', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` })
				.fromTo('.sl1 .ly1 img', { objectPosition: `0% 50%` }, { objectPosition: `100% 50%`, duration: 2 }, 0)
				.fromTo('.sl1 .ly2 img', { objectPosition: `150% 50%` }, { objectPosition: `0% 50%`, duration: 2 }, 0)
				.fromTo('.sl1 .ic', { opacity: 0 }, { opacity: 1 }, 2.5)

				// slide 2
				.to('.sl2', { display: `flex` })
				.fromTo('.sl2 .ly1', { opacity: 0, scale: 1 }, { opacity: 3, duration: 2, scale: `+=.2` }, `+=1`)
				.fromTo('.sl2 .ly1 img', { objectPosition: `40% 50%` }, { objectPosition: `60% 50%`, duration: 5 }, `<`)
				.fromTo('.sl2 .ly2 img', { opacity: 0 }, { opacity: 1, scale: '+=0', duration: 0.3 }, `<`)
				.fromTo('.sl2 .ly2 img', { objectPosition: `100% 50%` }, { objectPosition: `0% 50%`, duration: 3 }, `<`)
				.fromTo('.sl2 .dm', { opacity: 0 }, { opacity: 0.4 }, '<')
				.fromTo('.sl2 .title', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` }, '-=2.7')
				.fromTo('.sl2 .text', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` }, '-=2')
				.fromTo('.sl2 .ic', { opacity: 0 }, { opacity: 1 }, '-=1.8')
				.fromTo('.sl2 .icl', { opacity: 0 }, { opacity: 1 }, '-=1.8')

				// slide 3
				.fromTo(' .sl3 .ly1', { display: `flex`, opacity: 0, scale: 1 }, { opacity: 3, duration: 2, scale: 1 }, `+=3`)
				.to('.sl3', { display: `flex` }, `+=1`)
				.fromTo('.sl3 .ly1 img', { objectPosition: `40% 40%` }, { objectPosition: `60% 40%`, duration: 12 }, `<`)
				.fromTo('.sl3 .ly2 img', { opacity: 0 }, { opacity: 1, scale: '+=.2', duration: 0.3 }, `<`)
				.fromTo('.sl3 .ly2 img', { objectPosition: `0% 50%` }, { objectPosition: `90% 50%`, duration: 8 }, `<`)
				.fromTo('.sl3 .dm', { opacity: 0 }, { opacity: 0.4 }, '<')
				.fromTo('.sl3 .subtitle', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` }, '-=2.7')
				.fromTo('.sl3 .title', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` }, '-=.7')
				.fromTo('.sl3 .text', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` }, '-=.7')
				.fromTo('.sl3 .ic', { opacity: 0 }, { opacity: 1 }, '-=.6')
				.fromTo('.sl3 .icl', { opacity: 0 }, { opacity: 1 }, '-=.6')

				// slide 4
				.fromTo('.sl4 .ly1', { opacity: 0, scale: 1 }, { opacity: 3, duration: 2, scale: 1 }, `+=1`)
				.to('.sl4', { display: `flex` }, '<')
				.fromTo('.sl4 .ly1 img', { objectPosition: `0% 50%` }, { objectPosition: `60% 50%`, duration: 8 }, `<`)
				.fromTo('.sl4 .ly2 img', { opacity: 0 }, { opacity: 1, duration: 0.3 }, `<`)
				.fromTo('.sl4 .ly2 img', { objectPosition: `0% 50%` }, { objectPosition: `-40% 50%`, duration: 3 }, `<`)
				.fromTo('.sl4 .dm', { opacity: 0 }, { opacity: 0.4 }, '<')
				.fromTo('.sl4 .subtitle', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` }, '-=4')
				.fromTo('.sl4 .title', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50`, delay: 0.4 }, '<')
				.fromTo('.sl4 .text', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50`, delay: 0.8 }, '<')
				.fromTo('.sl4 .ic', { opacity: 0 }, { opacity: 1 }, '<')
				.fromTo('.sl4 .icl', { opacity: 0 }, { opacity: 1 }, '<')

				// slide 5
				.fromTo('.sl5 .ly1', { opacity: 0, scale: 1 }, { opacity: 3, duration: 0.3, scale: 1 }, `+=1`)
				.to('.sl5', { display: `flex` }, '<')
				.fromTo('.sl5 .ly1 img', { objectPosition: `70% 50%` }, { objectPosition: `30% 50%`, duration: 5 }, `<`)
				.fromTo('.sl5 .ly2 img', { opacity: 0 }, { opacity: 1, duration: 0.3 }, `<`)
				.fromTo('.sl5 .ly2 img', { objectPosition: `90% 50%` }, { objectPosition: `30% 50%`, duration: 5 }, `<`)
				.fromTo('.sl5 .ly3 img', { opacity: 0 }, { opacity: 1, duration: 0.5 }, `<`)
				.fromTo('.sl5 .ly3 img', { objectPosition: `100% 50%` }, { objectPosition: `0% 50%`, duration: 5 }, `<`)
				.fromTo('.sl5 .dm', { opacity: 0 }, { opacity: 0.4 }, '<')
				.fromTo('.sl5 .subtitle', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` }, '-=3.8')
				.fromTo('.sl5 .title', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50`, delay: 0.4 }, '-=3.6')
				.fromTo('.sl5 .text', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50`, delay: 0.8 }, '-=3.4')
				.fromTo('.sl5 .ic', { opacity: 0 }, { opacity: 1 }, '-=3.2')
				.fromTo('.sl5 .icl', { opacity: 0 }, { opacity: 1 }, '-=3.2')
		);
	};

	const anDektopLg = () => {
		gsap.set('.sl2,.sl3,.sl4,.sl5', { display: `none` });
		return (
			gsap
				.timeline({ defaults: { duration: 1 } })
				.fromTo('.sl1 .dm', { opacity: 0 }, { opacity: 0.4 })
				.fromTo(
					'.sl1 .title',
					{ opacity: 0, y: `+=50`, scale: `+=.5`, x: `+=300` },
					{ opacity: 1, y: `-=50`, scale: `-=.5`, x: `-=300` }
				)
				.fromTo('.sl1 .subtitle', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` })
				.fromTo(
					'.sl1 .ly1 img',
					{ objectPosition: `0% 50%`, scale: `+=.5`, x: `-=100` },
					{ objectPosition: `120% 50%`, x: `+=100`, duration: 2 },
					0
				)
				.fromTo(
					'.sl1 .ly2 img',
					{ objectPosition: `50% 0%`, scale: `+=.2`, x: `+=300` },
					{ objectPosition: `50% 0%`, x: `-=300`, duration: 2 },
					0
				)
				.fromTo('.sl1 .ic', { opacity: 0 }, { opacity: 1 }, 2.5)

				// slide 2
				.fromTo('.sl2 .ly1', { opacity: 0, scale: 1 }, { opacity: 3, duration: 2, scale: `+=.2` }, `+=1`)
				.to('.sl2', { display: `flex` }, '<')

				.fromTo(
					'.sl2 .ly1 img',
					{ objectPosition: `40% 50%` },
					{ objectPosition: `60% 50%`, x: `+=100`, duration: 5 },
					`<`
				)
				.fromTo('.sl2 .ly2 img', { opacity: 0, scale: `+=0` }, { opacity: 1, duration: 0.3 }, `<`)
				.fromTo(
					'.sl2 .ly2 img',
					{ objectPosition: `100% 50%` },
					{ objectPosition: `0% 50%`, x: '+=230', duration: 3 },
					`<`
				)
				.fromTo('.sl2 .dm', { opacity: 0 }, { opacity: 0.4 }, '<')
				.fromTo('.sl2 .title', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` }, '-=2.7')
				.fromTo('.sl2 .text', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` }, '-=2')
				.fromTo('.sl2 .ic', { opacity: 0 }, { opacity: 1 }, '-=1.8')
				.fromTo('.sl2 .icl', { opacity: 0 }, { opacity: 1 }, '-=1.8')

				// slide 3
				.fromTo('.sl3 .ly1', { opacity: 0, scale: 1 }, { opacity: 3, duration: 2, scale: 1 }, `+=1`)
				.to('.sl3', { display: `flex` }, '<')

				.fromTo(
					'.sl3 .ly1 img',
					{ objectPosition: `40% 40%`, scale: `+=.2`, x: `-=100` },
					{ objectPosition: `60% 40%`, x: `+=100`, duration: 12 },
					`<`
				)
				.fromTo('.sl3 .ly2 img', { opacity: 0 }, { opacity: 1, duration: 0.3 }, `<`)
				.fromTo(
					'.sl3 .ly2 img',
					{ objectPosition: `0% 50%`, y: 50, scale: '+=.4' },
					{ objectPosition: `50% 50%`, x: `-=300`, duration: 8 },
					`<`
				)
				.fromTo('.sl3 .dm', { opacity: 0 }, { opacity: 0.4 }, '<')
				.fromTo('.sl3 .subtitle', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` }, '-=2.7')
				.fromTo('.sl3 .title', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` }, '-=.7')
				.fromTo('.sl3 .text', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` }, '-=.7')
				.fromTo('.sl3 .ic', { opacity: 0 }, { opacity: 1 }, '-=.6')
				.fromTo('.sl3 .icl', { opacity: 0 }, { opacity: 1 }, '-=.6')

				// slide 4
				.fromTo('.sl4 .ly1', { opacity: 0, scale: 1 }, { opacity: 3, duration: 2, scale: 1 }, `+=1`)
				.to('.sl4', { display: `flex` }, '<')

				.fromTo(
					'.sl4 .ly1 img',
					{ objectPosition: `50% 50%`, scale: `+=.3` },
					{ objectPosition: `60% 50%`, x: `+=100`, duration: 8 },
					`<`
				)
				.fromTo('.sl4 .ly2 img', { opacity: 0 }, { opacity: 1, duration: 0.3 }, `<`)
				.fromTo(
					'.sl4 .ly2 img',
					{ objectPosition: `0% 50%`, scale: `+=.3` },
					{ objectPosition: `90% 50%`, x: `+=200`, duration: 8 },
					`<`
				)
				.fromTo('.sl4 .dm', { opacity: 0 }, { opacity: 0.4 }, '<')
				.fromTo('.sl4 .subtitle', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` }, '-=4')
				.fromTo('.sl4 .title', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50`, delay: 0.4 }, '<')
				.fromTo('.sl4 .text', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50`, delay: 0.8 }, '<')
				.fromTo('.sl4 .ic', { opacity: 0 }, { opacity: 1 }, '<')
				.fromTo('.sl4 .icl', { opacity: 0 }, { opacity: 1 }, '<')

				// slide 5
				.fromTo('.sl5 .ly1', { opacity: 0, scale: 1 }, { opacity: 3, duration: 0.3, scale: 1 }, `+=1`)
				.to('.sl5', { display: `flex` }, '<')

				.fromTo(
					'.sl5 .ly1 img',
					{ objectPosition: `70% 50%`, x: `-=20`, scale: `+=.15` },
					{ objectPosition: `30% 50%`, x: `+=20`, duration: 5 },
					`<`
				)
				.fromTo('.sl5 .ly2 img', { opacity: 0 }, { opacity: 1, duration: 0.3 }, `<`)
				.fromTo(
					'.sl5 .ly2 img',
					{ objectPosition: `90% 50%`, x: `-=100`, scale: `+=.15` },
					{ objectPosition: `30% 50%`, x: `+=100`, duration: 3 },
					`<`
				)
				.fromTo('.sl5 .ly3 img', { opacity: 0 }, { opacity: 1, duration: 0.5 }, `<`)
				.fromTo(
					'.sl5 .ly3 img',
					{ objectPosition: `100% 50%`, x: `-=100`, scale: `+=.15` },
					{ objectPosition: `0% 50%`, x: `+=100`, duration: 8 },
					`<`
				)
				.fromTo('.sl5 .dm', { opacity: 0 }, { opacity: 0.4 }, '<')
				.fromTo('.sl5 .subtitle', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50` }, '-=3.8')
				.fromTo('.sl5 .title', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50`, delay: 0.4 }, '-=3.6')
				.fromTo('.sl5 .text', { opacity: 0, y: `+=50` }, { opacity: 1, y: `-=50`, delay: 0.8 }, '-=3.4')
				.fromTo('.sl5 .ic', { opacity: 0 }, { opacity: 1 }, '-=3.2')
				.fromTo('.sl5 .icl', { opacity: 0 }, { opacity: 1 }, '-=3.2')
		);
	};

	// integrate with wp / swr
	useEffect(() => {
		//logic to match diff types of device
		const animation =
			window.innerWidth <= 640
				? anMobile()
				: window.innerWidth <= 1024
				? anTablet()
				: window.innerWidth <= 1440
				? anDektop()
				: anDektopLg();
		console.log(window.innerWidth);
		// this works, the problem seams to be "top top", as the element is already in the top
		ScrollTrigger.create({
			animation,
			trigger: `.wrapper`,
			start: 0,
			end: 2500,
			pin: true,
			pinSpacing: true,
			markers: false,
			scrub: true,
		});
	}, []);

	useEffect(() => {
		if (pathname.indexOf(`/pt`) > -1 || pathname === `/`) {
			setSlide(_slide);
		}
		if (pathname.indexOf(`/en`) > -1) {
			setSlide(_slide_en);
		}
		if (pathname.indexOf(`/es`) > -1) {
			setSlide(_slide_es);
		}
		console.log(pathname);
	}, [pathname]);

	return (
		<section className='wrapper w-screen h-screen overflow-hidden'>
			{/* slide 1 */}
			<article className='sl1 absolute left-0 top-0 w-screen h-screen flex flex-col items-center justify-center md:px-0 xl:px-72 -hidden'>
				<figure className='ly1 absolute left-0 top-0 w-full h-full'>
					<img src={`${p}/0-1.png`} className='w-full h-full object-cover' />
				</figure>
				<figure className='absolute ly2 left-0 top-0 w-full h-full'>
					<img src={`${p}/0-2.png`} className='w-full h-full object-cover' />
				</figure>
				<div className='dm absolute left-0 top-0 w-screen h-screen bg-black opacity-50' />
				{pathname.indexOf(`/en`) > -1 ? (
					<h2 className='relative title font-mon text-xl text-white uppercase -ml-40 mt-52 md:mt-40 md:-ml-96 md:pl-4 md:text-5xl xl:pl-0 xl:text-4xl xl:mt-52 2xl:text-6xl 2xl:mt-96 2xl:-ml-100'>
						{slide[0].title}
					</h2>
				) : (
					<h2 className='relative title font-mon text-xl text-white uppercase -ml-40 mt-52 md:mt-40 md:-ml-96 md:pl-4 md:text-5xl xl:pl-0 xl:text-6xl xl:mt-52 2xl:text-8xl 2xl:mt-96 2xl:-ml-100'>
						{slide[0].title}
					</h2>
				)}
				<h3 className='relative subtitle font-albra-bk text-6xl mt-2 text-white md:mt-4 md:-ml-20 md:text-9xl xl:ml-0 xl:text-10xl 2xl:text-12xl'>
					{slide[0].subtitle}
				</h3>
				<img
					src={icEntrar}
					alt=''
					className='ic relative w-12 h-12 mt-10 md:w-16 md:h-16 md:mt-10 md:-ml-4 xl:w-20 xl:h-20 md:self-center'
				/>
			</article>

			{/* slide 2 */}
			<article className='sl2 absolute left-0 top-0 w-screen h-screen flex flex-col items-center justify-center -hidden pointer-events-none'>
				<figure className='ly1 absolute left-0 top-0 w-full h-full'>
					<img src={`${p}/1-1.png`} className='w-full h-full object-cover' />
				</figure>

				<div className='dm absolute left-0 top-0 w-screen h-screen bg-black opacity-50' />

				<h2 className='relative title text-center font-albra-bd text-white text-5xl mt-20 md:mt-52 lg:text-8xl xl:text-8xl 2xl:text-9xl 2xl:mt-96'>
					{slide[1].title}
				</h2>
				<p className='relative text w-full font-mon-bd text-white text-center text-sm px-5 mt-5 md:px-40 lg:text-xl xl:px-72 xl:text-2xl 2xl:px-104 2xl:text-3xl'>
					{slide[1].text}
				</p>
				<Link to={slide[1].url} className='relative pointer-events-auto'>
					<img src={icEntrar} alt='' className='ic relative w-12 h-12 md:w-16 md:h-16 mt-10 md:mt-8' />
					<p className='icl relative font-mon mt-2 md:mt-2 uppercase text-center text-xs text-white '>entrar</p>
				</Link>

				<figure className='ly2 absolute left-0 top-0 w-full h-full'>
					<img src={`${p}/1-2.png`} className='w-full h-full object-cover' />
				</figure>
			</article>

			{/* slide 3 */}
			<article className='sl3 absolute left-0 top-0 w-screen h-screen flex flex-col items-center justify-center -hidden'>
				<figure className='ly1 absolute left-0 top-0 w-full h-full'>
					<img src={`${p}/2-1.png`} className='w-full h-full object-cover' />
				</figure>
				<figure className='ly2 absolute left-0 top-0 w-full h-full'>
					<img src={`${p}/2-2.png`} className='w-full h-full object-cover' />
				</figure>
				<div className='dm absolute left-0 top-0 w-screen h-screen bg-black opacity-50' />
				{/* text */}
				<h3 className='relative subtitle text-center font-mon uppercase text-white text-3xl px-7 mt-40 xl:px-20 xl:text-4xl 2xl:text-6xl md:mt-52 2xl:mt-96'>
					{slide[2].subtitle}
				</h3>
				<h2 className='relative title text-center font-albra-bd text-white text-6xl lg:text-8xl xl:text-10xl 2xl:text-12xl '>
					{slide[2].title}
				</h2>
				<p className='relative text w-full px-4 font-mon-bd text-white text-center text-sm md:px-48 xl:px-52 xl:text-2xl 2xl:px-72 2xl:text-3xl '>
					{slide[2].text}
				</p>
				<Link to={slide[2].url} className='relative pointer-events-auto'>
					<img src={icEntrar} alt='' className='ic relative w-12 h-12 md:w-16 md:h-16 mt-10 md:mt-4' />
					<p className='icl relative font-mon mt-4 uppercase text-center text-xs text-white md:mt-4 '>entrar</p>
				</Link>
			</article>

			{/* slide 4 */}
			<article className='sl4 absolute left-0 top-0 w-screen h-screen flex flex-col items-center justify-center -hidden'>
				<figure className='ly1 absolute left-0 top-0 w-screen h-screen'>
					<img src={`${p}/3-1.jpg`} className='w-full h-full object-cover' />
				</figure>
				<div className='dm absolute left-0 top-0 w-screen h-screen bg-black opacity-50' />
				<h3 className='relative subtitle text-center font-mon uppercase text-white text-3xl px-7 mt-40 xl:px-20 xl:text-4xl 2xl:text-6xl md:mt-52 2xl:mt-96'>
					{slide[3].subtitle}
				</h3>
				<h2 className='relative title text-center font-albra-bd text-white text-6xl lg:text-8xl xl:text-10xl 2xl:text-12xl '>
					{slide[3].title}
				</h2>
				<figure className='ly2 absolute left-0 top-0 w-screen h-screen'>
					<img src={`${p}/3-2.png`} className='w-full h-full object-cover' />
				</figure>
				<p className='relative text w-full px-4 font-mon-bd text-white text-center text-sm md:px-48 xl:px-52 xl:text-2xl 2xl:px-72 2xl:text-3xl '>
					{slide[3].text}
				</p>
				<Link to={slide[3].url} className='relative pointer-events-auto'>
					<img src={icEntrar} alt='' className='ic relative w-12 h-12 md:w-16 md:h-16 mt-10 md:mt-4' />
					<p className='icl relative font-mon mt-4 uppercase text-center text-xs text-white md:mt-4 '>entrar</p>
				</Link>
			</article>

			{/* slide 5 */}
			<article className='sl5 absolute left-0 top-0 w-screen h-screen flex flex-col items-center justify-center -hidden'>
				<figure className='ly1 absolute left-0 top-0 w-screen h-screen'>
					<img src={`${p}/4-1.png`} className='w-full h-full object-cover' />
				</figure>
				<figure className='ly2 absolute left-0 top-0 w-screen h-screen'>
					<img src={`${p}/4-2.png`} className='w-full h-full object-cover' />
				</figure>
				<div className='dm absolute left-0 top-0 w-screen h-screen bg-black opacity-50' />
				<h3 className='relative subtitle text-center font-mon uppercase text-white text-lg px-7 mt-40 md:text-2xl xl:px-20 xl:text-4xl 2xl:text-5xl md:mt-52 2xl:mt-96'>
					{slide[4].subtitle}
				</h3>
				<h2 className='relative title text-center font-albra-bd text-white text-5xl md:text-8xl lg:text-8xl xl:text-9xl 2xl:text-11xl '>
					{slide[4].title}
				</h2>
				<p className='relative text w-full px-4 font-mon-bd text-white text-center text-sm md:text-sm md:px-48 xl:px-72 xl:text-2xl 2xl:px-96 2xl:text-3xl '>
					{slide[4].text}
				</p>
				<figure className='ly3 absolute left-0 top-0 w-screen h-screen'>
					<img src={`${p}/4-3.png`} className='w-full h-full object-cover' />
				</figure>
				<Link to={slide[4].url} className='relative pointer-events-auto'>
					<img src={icEntrar} alt='' className='ic relative w-12 h-12 md:w-16 md:h-16 mt-10 md:mt-4' />
					<p className='icl relative font-mon mt-4 uppercase text-center text-xs text-white md:mt-2'>entrar</p>
				</Link>
			</article>
		</section>
	);
};
