import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

export default React.memo(({ children }) => {
	return <View {...a.view}>{children}</View>;
});

const View = styled(motion.article)`
	pointer-events: none;
`;

const a = {
	view: {
		animate: { opacity: 1 },
		initial: { opacity: 0 },
		exit: { opacity: 0 },
	},
};
