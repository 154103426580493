/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { prepare } from '../utils';
import { fetcher, QUERY_SINGLE_PAGE } from '../services';
import { ReactComponent as CloseIcon } from '../assets/icons/fechar.svg';
import ReactPlayer from 'react-player';
import useSWR from 'swr';
import first from 'lodash/first';
import { nanoid } from 'nanoid';
import { useMouseEvents } from 'beautiful-react-hooks';

import Loader from '../assets/icons/loader.gif';
import * as c from '../components';

export default () => {
	const history = useHistory();
	const match = useRouteMatch();

	const lockRef = useRef(false);
	const mouseRef = useRef(0);

	const [galleryData, setGalleryData] = useState(null);
	const [galleryIndex, setGalleryIndex] = useState(null);
	const [singleData, setSingleData] = useState(null);

	const [variables, setVariables] = useState(null);
	const [blocks, setBlocks] = useState(null);

	const { path, url, params } = match;
	const { pathname } = useLocation();
	const { data, error } = useSWR(variables ? [QUERY_SINGLE_PAGE, variables] : null, fetcher);

	const { onMouseDown, onMouseUp } = useMouseEvents();

	onMouseDown((e) => (mouseRef.current = e));
	onMouseUp((e) => {
		const { clientX: ix, clientY: iy } = mouseRef.current;
		const { clientX: x, clientY: y } = e;
		const dx = Math.abs(ix - x);
		const dy = Math.abs(iy - y);

		console.log(dx, dy, ix, iy, x, y);

		lockRef.current = dx > 5 || dy > 5;
		console.log('lockRef.current', lockRef.current);
	});

	const presentModalSingle = (data) => {
		// clean url first
		dismiss();
		// then impose id
		let _url = `${window.location.pathname}/i/${data.img.id}`;
		_url = _url.replace('//', '/');

		// then "navigate"
		console.log(_url);
		window.history.replaceState({}, '', _url);
		setSingleData(data);
	};

	const presentModalGallery = (id, index, data) => {
		dismiss();

		let _url = `${window.location.pathname}/g/${id}/${index}`;
		_url = _url.replace('//', '/');

		window.history.replaceState({}, '', _url);

		setGalleryIndex(index);
		setGalleryData(data);
	};

	const handleMobileGalleryTap = (id, index, data) => {
		console.log('handleMobileGalleryTap');
		// setTimeout(() => {
		// 	console.log('handleMobileGalleryTap', lockRef.current);
		// 	if (!lockRef.current) {
		// 		presentModalGallery(id, index, data);
		// 	}
		// }, 200);
		presentModalGallery(id, index, data);
	};

	const dismiss = () => {
		let target = window.location.pathname;
		let idx = target.indexOf('/g/');

		if (idx !== -1) {
			target = url.substr(0, idx);
		}

		idx = target.indexOf('/i/');
		if (idx !== -1) {
			target = url.substr(0, idx);
		}

		window.history.pushState({}, '', target);
	};

	const dismissModalGallery = () => {
		dismiss();
		setGalleryData(null);
	};

	const dismissModalImage = () => {
		dismiss();
		setSingleData(null);
	};

	const renderTemplateBlock = ({ block, index }) => {
		// console.log('----');
		// console.log(block.type);
		// separar toda a parte textual para fazer um spread preparando o conteudo que entra
		switch (block.type) {
			case `1A`:
			case `1B`:
			case `1C`: {
				const pict = first(block.gallery);

				return (
					<c.Block key={block.id} id={block.id} cursor={index === 0 ? `scroll` : ``}>
						<c.Figure image={pict} behaviour='partial' onTap={presentModalSingle} />
						<c.Text
							type={block.type}
							title={block.title}
							caption={block.obs}
							leading={block.subtitle}
							article={block.text}
						/>
						{/* {(pict?.title || pict?.imgAuthor) && (
							<p className='font-mon-bd text-sm text-white ml-72 -mt-20 2xl:-mt-10'>
								{pict.title}
								<span className='block font-mon italic text-xs text-white mt-1'>{pict.imgAuthor}</span>
							</p>
						)} */}
					</c.Block>
				);
			}
			case `2A`:
				const pict = first(block.gallery);
				return (
					<c.Block key={block.id} id={block.id}>
						<c.Figure image={pict} behaviour='cover' onTap={presentModalSingle} />
						<c.Text
							type={block.type}
							title={block.title}
							caption={block.obs}
							leading={block.subtitle}
							article={block.text}
							bottom
							center
						/>
						{/* {(pict?.title || pict?.imgAuthor) && (
							<p className='font-mon-bd text-sm text-white ml-72 -mt-20 2xl:-mt-10'>
								{pict.title}
								<span className='block font-mon italic text-xs text-white mt-1'>{pict.imgAuthor}</span>
							</p>
						)} */}
					</c.Block>
				);
			case `2A-2`: {
				const pict = first(block.gallery);

				return (
					<c.Block key={block.id} id={block.id}>
						<c.Figure image={pict} behaviour='full' onTap={presentModalSingle} />
						<c.Text
							type={block.type}
							title={block.title}
							caption={block.obs}
							leading={block.subtitle}
							article={block.text}
							bottom
							center
						/>
						{/* {(pict?.title || pict?.imgAuthor) && (
							<p className='font-mon-bd text-sm ml-72 2xl:px-56 text-white mx-auto -mt-20 2xl:-mt-20'>
								{pict.title}
								<span className='block font-mon italic text-xs text-white mt-1'>{pict.imgAuthor}</span>
							</p>
						)} */}
					</c.Block>
				);
			}
			case `3A`: {
				const pict = first(block.gallery);
				return (
					<c.Block key={block.id} id={block.id} cursor={index === 0 ? `scroll` : ``}>
						<c.Figure image={pict} behaviour='cover' onTap={presentModalSingle} first />
						<c.Text
							type={block.type}
							title={block.title}
							caption={block.obs}
							leading={block.subtitle}
							article={block.text}
							bottom
						/>
						{/* {(pict?.title || pict?.imgAuthor) && (
							<p className='font-mon-bd text-sm text-white ml-72 -mt-20 2xl:-mt-10'>
								{pict.title}
								<span className='block font-mon italic text-xs text-white mt-1'>{pict.imgAuthor}</span>
							</p>
						)} */}
					</c.Block>
				);
			}
			case `3A-2`: {
				const pict = first(block.gallery);
				return (
					<c.Block key={block.id} id={block.id} cursor={index === 0 ? `scroll` : ``}>
						<c.Figure image={pict} behaviour='full' onTap={presentModalSingle} first />
						<c.Text
							type={block.type}
							title={block.title}
							caption={block.obs}
							leading={block.subtitle}
							article={block.text}
							bottom
						/>
						{/* {(pict?.title || pict?.imgAuthor) && (
							<p className='font-mon-bd text-sm text-white ml-72 -mt-20 2xl:-mt-10'>
								{pict.title}
								<span className='block font-mon italic text-xs text-white mt-1'>{pict.imgAuthor}</span>
							</p>
						)}{' '} */}
					</c.Block>
				);
			}
			case `3D`: {
				const pict = first(block.gallery);
				return (
					<c.Block key={block.id} id={block.id} cursor={index === 0 ? `scroll` : ``}>
						<c.Figure image={pict} behaviour='full' onTap={presentModalSingle} first />
						<c.Text
							type={block.type}
							title={block.title}
							caption={block.obs}
							leading={block.subtitle}
							article={block.text}
							bottom
							center
						/>
						{/* {(pict?.title || pict?.imgAuthor) && (
							<p className='font-mon-bd text-sm text-white px-7 text-center -mt-20 2xl:-mt-10'>
								{pict.title}
								<span className='block font-mon italic text-xs text-white mt-1 text-center'>{pict.imgAuthor}</span>
							</p>
						)} */}
					</c.Block>
				);
			}
			case `4A`: {
				return (
					<c.Block key={block.id} id={block.id} cursor={index === 0 ? `scroll` : ``}>
						<c.Text
							type={block.type}
							title={block.title}
							caption={block.obs}
							leading={block.subtitle}
							article={block.text}
							bottom
							center
						/>
					</c.Block>
				);
			}
			case `4B`:
			case `4C`: {
				return (
					<c.Block key={block.id} id={block.id} cursor={index === 0 ? `scroll` : ``}>
						<c.Text
							type={block.type}
							title={block.title}
							caption={block.obs}
							leading={block.subtitle}
							article={block.text}
						/>
					</c.Block>
				);
			}
			case `4D`: {
				return (
					<c.Block key={block.id} id={block.id} cursor={index === 0 ? `scroll` : ``}>
						<c.Text
							type={block.type}
							title={block.title}
							caption={block.obs}
							leading={block.subtitle}
							article={block.text}
							center
						/>
					</c.Block>
				);
			}
			case `5A`: {
				return (
					<>
						<div style={{ height: `5vw` }} />
						<c.ScrollSlides key={block.id} id={block.id} slides={block.gallery} />
					</>
				);
			}
			case `7A`:
			case `7B`: {
				return (
					<c.Block key={block.id} id={block.id}>
						<c.Gallery id={block.id} data={block.gallery} onTap={presentModalGallery} />
						<c.Text
							type={block.type}
							title={block.title}
							leading={block.text}
							subtitle={block.subtitle}
							caption={block.obs}
						/>
					</c.Block>
				);
			}
			case '8A': {
				return (
					<figure className='relative w-9/12 aspect-w-16 aspect-h-9 mx-auto'>
						<ReactPlayer url={block.video.mediaItemUrl} width='100%' height='100%' controls />
					</figure>
				);
			}
			case '8B': {
				return (
					<figure className='relative w-9/12 aspect-w-16 aspect-h-9 bg-gray-900 mx-auto'>
						<ReactPlayer url={block.externalVideo.mediaItemUrl} width='100%' height='100%' controls />
					</figure>
				);
			}
			default: {
				return (
					<div key={Math.random()} style={{ backgroundColor: `red`, fontSize: 100, color: `white` }}>
						{block.type}
					</div>
				);
			}
		}
	};

	const renderTemplateBlockMobile = ({ block, index }) => {
		const { title, subtitle, leading, text, caption, gallery, obs } = block;

		// console.log(block);

		switch (block.type) {
			case `1A`:
			case `1B`:
			case `1C`: {
				return (
					<section
						key={block.id}
						className={`relative w-screen h-auto flex flex-col p-7 ${index === 0 ? `pt-0` : ``} overflow-x-hidden`}
					>
						{gallery && (
							<figure className='opacity-80' aria-labelledby={`figure-${index}`}>
								<img src={gallery[0].img.mediaItemUrl} alt={gallery[0].ariaDescription} />
							</figure>
						)}
						{title && (
							<h2
								dangerouslySetInnerHTML={{ __html: prepare(title) }}
								className='relative font-albra-bd text-5xl text-white mt-8'
							/>
						)}
						{subtitle && (
							<p dangerouslySetInnerHTML={{ __html: prepare(subtitle) }} className='relative font-mon text-lg mt-5' />
						)}
						{leading && (
							<p
								className='relative font-mon text-sm mt-5'
								dangerouslySetInnerHTML={{
									__html: prepare(leading),
								}}
							/>
						)}
						{text && (
							<p
								dangerouslySetInnerHTML={{ __html: prepare(text) }}
								className='relative h-50 font-mon text-base mt-5'
							/>
						)}
						{caption && <p dangerouslySetInnerHTML={{ __html: prepare(caption) }} className='relative h-50' />}
						{gallery && (
							<p id={`figure-${index}`} className='font-mon-bd text-sm text-white mt-7'>
								{gallery[0].title}
								<span className='block font-mon italic text-xs text-white mt-1 opacity-70'>{gallery[0].imgAuthor}</span>
							</p>
						)}
					</section>
				);
			}
			case `2A`:
			case `3A`:
			case `3A-2`:
			case `4A`:
			case `4B`:
			case `4C`:
			case `2A-2`: {
				return (
					<section
						key={block.id}
						className={`relative w-screen h-auto flex flex-col p-7 ${index === 0 ? `pt-0` : ``} px-0 overflow-x-hidden`}
					>
						{gallery && (
							<figure aria-labelledby={`figure-${index}`}>
								<img src={gallery[0].img.mediaItemUrl} alt={gallery[0].ariaDescription} />
							</figure>
						)}
						{title && (
							<h2
								dangerouslySetInnerHTML={{ __html: prepare(title) }}
								className={`relative font-albra text-4xl text-white mt-7  px-7`}
							/>
						)}
						{subtitle && (
							<p
								dangerouslySetInnerHTML={{ __html: prepare(subtitle) }}
								className='relative font-mon text-lg mt-5 px-7'
							/>
						)}
						{leading && (
							<p
								className='relative font-mon text-sm mt-5 px-7'
								dangerouslySetInnerHTML={{
									__html: prepare(leading),
								}}
							/>
						)}
						{text && (
							<p dangerouslySetInnerHTML={{ __html: prepare(text) }} className='relative px-7 font-mon text-sm mt-5' />
						)}
						{caption && <p dangerouslySetInnerHTML={{ __html: prepare(caption) }} className='relative px-7' />}
						{gallery && (
							<p id={`figure-${index}`} className='font-mon-bd text-sm text-white mt-7 px-7'>
								{gallery[0].title}
								<span className='block font-mon italic text-xs text-white mt-1'>{gallery[0].imgAuthor}</span>
							</p>
						)}
					</section>
				);
			}
			case `4D`:
			case `3D`: {
				return (
					<section
						key={block.id}
						className={`relative w-screen h-auto flex flex-col p-7 ${index === 0 ? `pt-0` : ``} px-0 overflow-x-hidden`}
					>
						{gallery && (
							<figure aria-labelledby={`figure-${index}`}>
								<img src={gallery[0].img.mediaItemUrl} alt={gallery[0].ariaDescription} />
							</figure>
						)}
						{title && (
							<h2
								dangerouslySetInnerHTML={{ __html: prepare(title) }}
								className='relative font-albra text-4xl text-white mt-7 px-7 text-center'
							/>
						)}
						{subtitle && (
							<p
								dangerouslySetInnerHTML={{ __html: prepare(subtitle) }}
								className='relative font-mon text-base mt-5 px-7 mt-5 text-center italic'
							/>
						)}
						{leading && (
							<p
								className='relative font-mon text-sm mt-5 px-7'
								dangerouslySetInnerHTML={{
									__html: prepare(leading),
								}}
							/>
						)}
						{text && (
							<p
								dangerouslySetInnerHTML={{ __html: prepare(text) }}
								className='relative px-7 font-mon text-base mt-5 text-center'
							/>
						)}
						{caption && (
							<p dangerouslySetInnerHTML={{ __html: prepare(caption) }} className='relative px-7 text-center' />
						)}
						{gallery && (
							<p id={`figure-${index}`} className='font-mon-bd text-sm text-white mt-7 px-7 text-center'>
								{gallery[0].title}
								<span className='block font-mon italic text-xs text-white mt-1 text-center'>
									{gallery[0].imgAuthor}
								</span>
							</p>
						)}
					</section>
				);
			}
			case `5A`: {
				return (
					<section
						key={block.id}
						className={`relative w-screen h-auto flex flex-col mt-5 ${index === 0 ? `pt-0` : ``} overflow-x-hidden`}
					>
						<article>
							{gallery?.map((item) => (
								<figure aria-labelledby={`figure-${index}`} className='mt-10'>
									<img src={item.img.mediaItemUrl} alt={item.ariaDescription} />
									<figcaption id={`figure-${index}`} className='font-mon-bd text-sm text-white mt-7 px-7'>
										{item.title}
										<span className='block font-mon italic text-xs text-white mt-1 opacity-70'>{item.imgAuthor}</span>
									</figcaption>
								</figure>
							))}
						</article>
					</section>
				);
			}
			case `7A`:
			case `7B`: {
				return (
					<section
						key={`s-${block.id}`}
						className={`relative w-screen h-auto flex flex-col mt-5 ${index === 0 ? `pt-0` : ``} overflow-x-hidden`}
					>
						<article className='relative'>
							<motion.section className='w-screen h-72 overflow-y-hidden pl-7'>
								<motion.div
									drag={`x`}
									dragConstraints={{ left: -(28 + (28 + 208) * gallery?.length - window.innerWidth), right: 0 }}
									className='flex space-x-7'
								>
									{gallery?.map((item, index) => (
										<figure
											key={item.id}
											aria-labelledby={`figure-${index}`}
											className='mt-7 flex-shrink-0 w-52 h-72 overflow-hidden'
										>
											<motion.img
												src={item.img.mediaItemUrl}
												alt={item.ariaDescription}
												className='w-full h-full object-cover'
												onTap={() => handleMobileGalleryTap(block.id, index, gallery)}
											/>

											<figcaption id={`figure-${index}`} className='sr-only font-mon-bd text-sm text-white mt-7 px-7'>
												{item.title}
												<span className='block font-mon italic text-xs text-white mt-1'>{item.imgAuthor}</span>
											</figcaption>
										</figure>
									))}
								</motion.div>
							</motion.section>
							{title && (
								<p
									dangerouslySetInnerHTML={{ __html: prepare(title) }}
									className='relative font-mon text-lg mt-5 px-7 uppercase'
								/>
							)}
							{subtitle && (
								<p
									dangerouslySetInnerHTML={{ __html: prepare(subtitle) }}
									className='relative font-mon text-lg mt-5 px-7 uppercase'
								/>
							)}
							{text && (
								<p
									dangerouslySetInnerHTML={{
										__html: prepare(text),
									}}
									className='relative px-7 font-mon mt-4 text-base'
								/>
							)}
							{obs && (
								<p
									dangerouslySetInnerHTML={{
										__html: prepare(obs),
									}}
									className='relative px-7 font-mon mt-4 text-xs'
								/>
							)}{' '}
						</article>
					</section>
				);
			}
			case '8A': {
				return (
					<figure className='relative w-full aspect-w-16 aspect-h-9 bg-gray-900'>
						<ReactPlayer url={block.video.mediaItemUrl} width='100%' height='100%' inline controls />
					</figure>
				);
			}
			case '8B': {
				return (
					<figure className='relative w-full aspect-w-16 aspect-h-9 bg-gray-900'>
						<ReactPlayer url={block.externalVideo.mediaItemUrl} width='100%' height='100%' inline />
					</figure>
				);
			}
			default: {
				return <div className='w-screen text-2xl text_white'>{block.type}</div>;
			}
		}
	};

	useEffect(() => {
		// reset the scroll on mount
		// check this
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (params) {
			const uri = `/${params.locale}/${params.section}/${params.page}`;
			console.log(url, pathname);
			setVariables({ uri });
		}
	}, [params]);

	useEffect(() => {
		if (data?.page?.tmpl_internas?.blocks) {
			setGalleryData(null);
			setSingleData(null);

			const _blocks = [...data.page.tmpl_internas.blocks];
			_blocks.map((block, index) => (block.id = `${data.page.id}___${index}`));
			console.log('---');
			console.log(_blocks);
			setBlocks(_blocks);

			if (params.type === `g`) {
				const _blocks = [...data.page.tmpl_internas.blocks];
				const _block = _blocks.find((e) => e.id === params.modal);

				setGalleryIndex(params.index);
				setGalleryData(_block.gallery);
			}

			if (params.type === `i`) {
				// locate internally

				const _blocks = [...data.page.tmpl_internas.blocks];

				_blocks.find((block) => {
					const gallery = block.gallery;
					console.log(block);
					let founded = null;
					if (gallery) {
						gallery.map((entry) => {
							if (entry.img.id === params.modal) {
								founded = entry;
							}
						});
					}

					if (founded) {
						setSingleData(founded);
					}
				});
			}
		}
	}, [data, params]);

	return (
		<>
			<c.NotMobile>
				<main>
					{blocks?.map((block, index) => renderTemplateBlock({ block, index }))}
					{galleryData && <RouteGallery index={galleryIndex} data={galleryData} onClose={dismissModalGallery} />}
					<AnimatePresence>
						{singleData && <RouteSingle data={singleData} onClose={dismissModalImage} />}
					</AnimatePresence>
				</main>
				<c.Footer url={url} />
				<AnimatePresence exitBeforeEnter>{!data && <c.Loader />}</AnimatePresence>
			</c.NotMobile>
			<c.Mobile>
				<main>
					<motion.article {...A} className='w-screen h-auto min-h-screen text-5xl text-white'>
						{blocks?.map((block, index) => renderTemplateBlockMobile({ block, index }))}
					</motion.article>
					{galleryData && <RouteGallery index={galleryIndex} data={galleryData} onClose={dismissModalGallery} />}
				</main>
				<c.Footer url={url} />
				<AnimatePresence exitBeforeEnter>{!data && <c.Loader />}</AnimatePresence>
			</c.Mobile>
		</>
	);
};

const A = {
	variants: {
		initial: { opacity: 0 },
		exit: { opacity: 0 },
		enter: { opacity: 1 },
	},
	animate: `enter`,
	initial: `initial`,
	exit: `exit`,
};

const RouteGallery = ({ data, index, onClose }) => {
	return <c.ModalGallery data={data} index={index} onClose={onClose} />;
};

const RouteSingle = ({ data, onClose }) => {
	const [loading, setLoading] = useState(true);
	return (
		<motion.figure
			initial={{ opacity: 0 }}
			animate={{ opacity: 1, transition: { when: `beforeChildren` } }}
			exit={{ opacity: 0 }}
			onTap={onClose}
			className='fixed left-0 top-0 z-50 w-screen h-screen bg-gray-800 bg-opacity-90 flex flex-col items-center justify-center'
		>
			<motion.img
				src={data.img.mediaItemUrl}
				alt={data.ariaDescription}
				initial={{ opacity: 0 }}
				animate={{ opacity: loading ? 0 : 1 }}
				exit={{ opacity: 0 }}
				className='w-full h-5/6 object-contain mt-auto p-4'
				onLoad={() => setLoading(false)}
			/>
			<motion.figcaption
				initial={{ y: 10, opacity: 0 }}
				animate={{ y: 0, opacity: 1, transition: { delay: 0.5, ease: [0.53, 0.02, 0.49, 1] } }}
				className='px-4 pb-2 w-screen bg-red text-center mb-auto'
			>
				<p className='text-mon text-2xl text-white'>{data.title}</p>
				<p className='text-mon text-sm text-white'>{data.imgAuthor}</p>
				<p className='text-mon text-sm text-white italic'>{data.imgCredits}</p>
			</motion.figcaption>
			<AnimatePresence>
				{loading && (
					<motion.img
						src={Loader}
						initial={{ opacity: 0 }}
						animate={{ opacity: 1, transition: { delay: 1.5 } }}
						exit={{ opacity: 0 }}
						className='absolute left-1/2 top-1/2 -mt-10 -ml-10 w-20 h-20'
					/>
				)}
			</AnimatePresence>
			<CloseIcon style={{ position: `absolute`, right: `2vh`, top: `2vh`, pointerEvents: `auto` }} onClick={onClose} />
		</motion.figure>
	);
};
