import React from 'react';
import breakpoint, { map } from 'styled-components-breakpoint';
import MediaQuery from 'react-responsive';

export const Mobile = ({ children = null }) => <MediaQuery maxDeviceWidth={`737px`}>{children}</MediaQuery>;
export const NotMobile = ({ children = null }) => <MediaQuery minDeviceWidth={`737px`}>{children ? children : null}</MediaQuery>;
export const Tablet = ({ children = null }) => (
	<MediaQuery minDeviceWidth={`738px`} maxDeviceWidth={`1194px`}>
		{children ? children : null}
	</MediaQuery>
);
export const Desktop = ({ children = null }) => <MediaQuery minDeviceWidth={`1195px`}>{children ? children : null}</MediaQuery>;
export const NotDesktop = ({ children = null }) => <MediaQuery maxDeviceWidth={`1195px`}>{children ? children : null}</MediaQuery>;
