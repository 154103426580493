import { css } from 'styled-components';

export default css`
	.type-white {
		color: white;
	}

	.type-large-title {
		font-family: AlbraBold;
		font-size: 5.208vw;
	}

	.type-small-title {
		font-family: AlbraRegular;
		font-size: 2.5vw;
	}

	.type-subtitle {
		font-family: MontserratRegular;
		font-size: 1.944vw;
		line-height: 1.5;
	}

	.type-content {
		font-family: MontserratRegular;
		font-size: 0.8vw;
		line-height: 1.875;
	}

	.type-caption {
		font-family: MontserratBold;
		font-size: 0.8vw;
		line-height: 1.5;
	}

	.type-uppercase {
		text-transform: uppercase;
	}

	.type-breadcrumb {
		font-family: MontserratRegular;
		font-size: 1vw;
	}

	.type-breadcrumb-emphasis {
		font-family: AlbraBold;
		font-size: 1vw;
	}

	.type-leading {
		font-family: MontserratRegular;
		font-size: 1.4vw;
		line-height: 1.5;
	}
`;
